import React, { Component } from "react";
import ReactDOM from "react-dom";
import { withRouter } from "react-router-dom";
import { loadModules } from "esri-loader";
// import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import * as navActions from "../../common/actions";
import MapSearch from "../../common/esrimap/MapSearch";
// import { arcgisToGeoJSON } from "@esri/arcgis-to-geojson-utils";
import MapEditing from "./MapEditingApplication";
import { MuiThemeProvider } from "@material-ui/core/styles/";
import { theme } from "../../../api/constants";
// eslint-disable-next-line
var showChecked = false; //used for checkboxes showing checkd or not for each layer

 
const styles = (theme) => ({
    fullHeight: {
        height: "calc(100% + 16px)",
        margin: "0 !important",
        width: "100% !important",
        marginTop: "-16px !important",
        paddingBottom: "0 !important",
        [theme.breakpoints.down("960")]: {
            paddingLeft: "47px !important",
            paddingTop: "16px !important",
        },
    },
    height: {
        height: "calc(100% + 16px)",
        margin: "0 !important",
        width: "100% !important",
        //marginTop: "-16px !important",
        paddingBottom: "0 !important",
    },
    smallHeight: {
        height: 500,
        marginTop: "12px !important",
        marginBottom: "12px !important",
    },
});

export class BaseMapControlApplication extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawTouched: false,
            measurementOpen: false,
        };

        this.mapRef = React.createRef();

    }

    historyPush = (url) => {
        const { history } = this.props;
        history.push(url);
    };

    //send geometry and street etc. upwards to application
    handleSearchComplete =(event) => {
        const _this = this;
        if(_this.props.saveAddress){//Ninemile app
            _this.props.saveAddress(event.results[0].results[0].feature.geometry, event.results[0].results[0].feature.attributes )
        
        }else if(_this.props.setDrawingFeature){ //when search is complete -- not sure if we make a geometry lets make them do it with the draw edit tools so skip the below

            // let tempgeo = {}
            // let geom = event.results[0].results[0].feature.geometry
            // tempgeo['coordinates'] = []
            // tempgeo['coordinates'][0] = geom.latitude
            // tempgeo['coordinates'][1] = geom.longitude
            // _this.props.setDrawingFeature(tempgeo) 

            //to show application location that user entered--doing this isnt good as the zoom too wasnt working I assume this function has a callback??
            // let tempattributes = {
            //     Name: "Searched Location"
            // }
            // //only for application popup of permits
            // let popupTemplate = {
            //     title: "{Name}",
            //     content: "{Description}"
            // }
            // _this.tempGraphicsLayer.graphics.removeAll() 
            // loadModules(
            //     ["esri/Graphic"],
            //     { css: true }
            // ).then(([Graphic]) => { 
            //     // let temp = _this._getPointGrapicWeb("blue",geom.latitude, geom.longitude, _this.view, Graphic, tempattributes, popupTemplate);
            //     // _this.tempGraphicsLayer.add(temp);  
            //     //zoom to the point
            //     _this.view.goTo({
            //         target: [geom.latitude, geom.longitude],
            //         zoom: 12
            //     }); 
            // })
      
        }
        
 
    } 
    componentDidMount() {
        const {
            layers,
            settings,
            viewOnly,
            geometry,
            setMapSettings,
            // navState,
            authState,
            cityID 
        } = this.props;

        // lazy load the required ArcGIS API for JavaScript modules and CSS
        loadModules(
            [
                "esri/Basemap",
                "esri/widgets/Fullscreen",
                "esri/layers/GraphicsLayer",
                "esri/widgets/Home",
                "esri/Map",
                "esri/views/MapView",
                "esri/widgets/Popup",
                "esri/widgets/Search",
                "esri/widgets/Track",
                "esri/core/watchUtils",
                "esri/config",
                "esri/Graphic",
                "esri/widgets/Sketch/SketchViewModel",
                "esri/geometry/support/webMercatorUtils"
            ],
            { css: true }
        ).then(([Basemap, Fullscreen, GraphicsLayer, Home, ArcGISMap, MapView, Popup, Search, Track, watchUtils, esriConfig, Graphic, SketchViewModel, webMercatorUtils]) => {
            var _this = this;
 
            esriConfig.apiKey = "AAPKf2e3a0e95055432995c143c76ec9d7acolbgIHxUiuqnTzAJN-soT9O7nlVATKm_6FrquwAVqfTuXBi0QAUuHui-C7NqPl7j";
 
            this.tempGraphicsLayer = new GraphicsLayer({ listMode: "hide" });

            //add the basemaps to a map instance
            const map = new ArcGISMap({
                basemap: new Basemap.fromId("arcgis-imagery"),
                layers: [this.tempGraphicsLayer],
            });

            // Map View Set Up
            var zoom = 13;
            var center = [];
            this.layer_count = [];

          
            zoom = layers ? layers[0].map_level : 11;
            center = layers ? [layers[0].x, layers[0].y] : null;
            

            // Allows Default Pop Up
            const defaultPopUp = new Popup({ defaultPopupTemplateEnabled: true });

            // MapView Instance, pass in map instance etc
            this.view = new MapView({
                container: this.mapRef.current,
                map: map,
                center: [center[0],center[1]],
                zoom,
                popup: defaultPopUp,
                spatialReference: {
                    wkid: 3857,
                },
            });

            watchUtils.whenFalse(this.view, "stationary", function () {
                if (!_this.view.stationary) {
                    watchUtils.whenTrueOnce(_this.view, "stationary", function () {
                        setMapSettings({
                            lat: _this.view.extent.center.latitude,
                            lng: _this.view.extent.center.longitude,
                            zoom: _this.view.zoom,
                        });
                    });
                }
            });

            var source = {
                countryCode: "US",
                defaultZoomScale: 2500,
                url: "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer?preferredLabelValues=matchedCity",
                name: "ArcGIS World Geocoding Service",
                outFields: ["Addr_type", "Match_addr", "StAddr", "City", "Postal"],
                placeholder: "Find project address, place, or intersection",
                singleLineFieldName: "SingleLine",
                suggestionsEnabled: true,
                withinViewEnabled: false,
            };

            //this keeps the search widget to be within the map view parameters
            // if (layers[0].geocoder_top) {
            //     loadModules(["esri/geometry/Extent"], { css: true }).then(([Extent]) => {
            //         const theExtent = new Extent({
            //             xmin: layers[0].geocoder_left,
            //             ymin: layers[0].geocoder_bottom,
            //             xmax: layers[0].geocoder_right,
            //             ymax: layers[0].geocoder_top,
            //             spatialReference: {
            //                 wkid: 4326,
            //             },
            //         });
            //         source["filter"] = {
            //             geometry: theExtent,
            //         };
            //     });
            // }

            var fullscreen = new Fullscreen({ view: this.view });
            var homeBtn = new Home({ view: this.view });
            var search = new Search({ view: this.view, includeDefaultSources: false, sources: [source], closeButton:false});
            
            search.on("search-complete", this.handleSearchComplete)
         
           
     
            homeBtn.goToOverride = function (view, goToParams) {
                return view.goTo({
                    target: [settings.longitude, settings.latitude],
                    zoom: settings.map_level,
                });
            };

            // var track = new Track({ view: this.view });

            // Add Reference Layers
            this.referenceLayers = [];
            // var has_webmap = false;

            layers
                .filter((x) => !x.is_basemap)
                .forEach((x) => {
                    var layer = null;

                    if (x.layer_type === "Tiled") {
                        loadModules(["esri/layers/TileLayer"], { css: true }).then(([TileLayer]) => {
                            layer = new TileLayer({
                                url: x.layerurl,
                                opacity: x.opacity,
                                visible: x.on_by_default,
                                title: x.name,
                                listMode: "hide",
                            });
                            addRefLayer(layer);
                        });
                    } else if (x.layer_type === "WMS") {
                        loadModules(["esri/layers/WMSLayer"], { css: true }).then(([WMSLayer]) => {
                            layer = new WMSLayer({
                                url: x.layerurl,
                                sublayers: x.wmslayers.split(",").map((x) => ({
                                    name: x,
                                })),
                                opacity: x.opacity,
                                visible: x.on_by_default,
                                title: x.name,
                                listMode: "hide",
                            });
                            addRefLayer(layer);
                        });
                    } else if (x.layer_type === "ArcGIS") {
                        if (x.tileddynamic === "Tiled") {
                            loadModules(["esri/layers/TileLayer"], { css: true }).then(([TileLayer]) => {
                                layer = new TileLayer({
                                    url: x.layerurl,
                                    opacity: x.opacity,
                                    visible: x.on_by_default,
                                    title: x.name,
                                    listMode: "hide",
                                });
                                addRefLayer(layer);
                            });
                        } else if (x.tileddynamic === "Dynamic") {
                            const c = function (f) {
                                loadModules(["esri/layers/MapImageLayer"], { css: true }).then(([MapImageLayer]) => {
                                    layer = new MapImageLayer({
                                        url: x.layerurl,
                                        opacity: x.opacity,
                                        title: x.name,
                                        visible: x.on_by_default,
                                        listMode: "hide",
                                    });
                                    addRefLayer(layer);
                                });
                            };
                            c();
                        } else if (x.tileddynamic === "Feature Service") {
                            loadModules(["esri/layers/FeatureLayer"], { css: true }).then(([FeatureLayer]) => {
                                layer = new FeatureLayer({
                                    url: x.layerurl,
                                    opacity: x.opacity,
                                    title: x.name,
                                    visible: x.on_by_default,
                                    outFields: ["*"],
                                    listMode: "hide",
                                });

                                //only load reference layers ones that we want for applications (22 = NineMileBoundary)
                                if(x.id === 22 || x.id === 52){
                                    // if(!this.props.disableSearch === true) //so after creating application we wont show boundary anymore
                                    addRefLayer(layer);
                                }
                              
                            });
                        }
                    } else if (x.layer_type === "Web Map") {
                        // has_webmap = true;
                        loadModules(["esri/WebMap"], { css: true }).then(([WebMap]) => {
                            const webmap = new WebMap({
                                portalItem: {
                                    id: x.webmap_id,
                                },
                            });
                            webmap
                                .load()
                                .then(function () {
                                    // grab all the layers and load them
                                    const allLayers = webmap.allLayers;
                                    const promises = allLayers.map(function (layer) {
                                        return layer.load();
                                    });
                                    return Promise.all(promises.toArray());
                                })
                                .catch(function (error) {
                                    const layers = webmap.layers.items.filter((x) => (x.loadStatus = "loaded"));

                                    var has_group = false;
                                    layers.forEach((l, idx) => {
                                        if (l.type === "group") has_group = true;
                                    });

                                    layers.forEach((l, idx) => {
                                        if (l.type === "group" || !has_group) map.add(l);
                                    });

                                    let arrListItemCreated = [];
                                    loadModules(["esri/widgets/LayerList"], { css: true }).then(([LayerList]) => {
                                        var layerList = new LayerList({
                                            view: _this.view,
                                            container: "referencecontainer",
                                            listItemCreatedFunction: (event) => {
                                                if (arrListItemCreated.indexOf(event.item.title) > -1) {
                                                    return false;
                                                } else {
                                                    arrListItemCreated.push(event.item.title);
                                                }
                                                const item = event.item;
                                                if (item.layer.type !== "group") {
                                                    item.panel = {
                                                        content: "legend",
                                                        open: item.layer.visible,
                                                    };
                                                    item.actionsSections = [
                                                        [
                                                            {
                                                                title: "Increase opacity",
                                                                className: "esri-icon-up",
                                                                id: "increase-opacity",
                                                            },
                                                            {
                                                                title: "Decrease opacity",
                                                                className: "esri-icon-down",
                                                                id: "decrease-opacity",
                                                            },
                                                        ],
                                                    ];
                                                }
                                            },
                                        });

                                        layerList.on("trigger-action", (event) => {
                                            const l_id = event.action.id;
                                            const l_layer = event.item.layer;
                                            if (l_id === "increase-opacity") {
                                                if (l_layer.opacity < 1) {
                                                    l_layer.opacity += 0.25;
                                                }
                                            } else if (l_id === "decrease-opacity") {
                                                if (l_layer.opacity > 0) {
                                                    l_layer.opacity -= 0.25;
                                                }
                                            }
                                        });
                                    });
                                })
                                .then(function (temp_layers) {
                                    if (!temp_layers) return;
                                    const layers = temp_layers;

                                    var has_group = false;
                                    layers.forEach((l, idx) => {
                                        if (l.type === "group") has_group = true;
                                    });

                                    layers.forEach((l, idx) => {
                                        if (l.type === "group" || !has_group) map.add(l);
                                    });

                                    let arrListItemCreated = [];
                                    loadModules(["esri/widgets/LayerList"], { css: true }).then(([LayerList]) => {
                                        var layerList = new LayerList({
                                            view: _this.view,
                                            container: "referencecontainer",
                                            listItemCreatedFunction: (event) => {
                                                if (arrListItemCreated.indexOf(event.item.title) > -1) {
                                                    return false;
                                                } else {
                                                    arrListItemCreated.push(event.item.title);
                                                }
                                                const item = event.item;
                                                if (item.layer.type !== "group") {
                                                    item.panel = {
                                                        content: "legend",
                                                        open: item.layer.visible,
                                                    };
                                                    item.actionsSections = [
                                                        [
                                                            {
                                                                title: "Increase opacity",
                                                                className: "esri-icon-up",
                                                                id: "increase-opacity",
                                                            },
                                                            {
                                                                title: "Decrease opacity",
                                                                className: "esri-icon-down",
                                                                id: "decrease-opacity",
                                                            },
                                                        ],
                                                    ];
                                                }
                                            },
                                        });

                                        layerList.on("trigger-action", (event) => {
                                            const l_id = event.action.id;
                                            const l_layer = event.item.layer;
                                            if (l_id === "increase-opacity") {
                                                if (l_layer.opacity < 1) {
                                                    l_layer.opacity += 0.25;
                                                }
                                            } else if (l_id === "decrease-opacity") {
                                                if (l_layer.opacity > 0) {
                                                    l_layer.opacity -= 0.25;
                                                }
                                            }
                                        });
                                    });
                                });
                        });
                    }

                    //adds all layers to the reference layer button is called from above in loadmodules
                    //this can add multiple layers  
                    const addRefLayer = (layer) => {
                        if (layer) {
                            map.add(layer);

                            this.layer_count.push({
                                index: x.order,
                                count: 1,
                            });

                            // Create DomNode and pass to legend widget
                            const dNode = document.createElement("div");
                            loadModules(["esri/widgets/Legend"], { css: true }).then(([Legend]) => {
                                const legend = new Legend(
                                    {
                                        view: this.view,
                                        layerInfos: [
                                            {
                                                layer: layer,
                                            },
                                        ],
                                    },
                                    dNode
                                );
                                _this.referenceLayers.push({ layer: layer, order: x.order, legend: legend });
                            });
                        }
                    };
                });
         
            // Add Widgets
            if (!viewOnly) {
                this.parentContainer3 = document.createElement("div");
                this.view.ui.add(this.parentContainer3, { position: "top-left", index: 0 });
                ReactDOM.render(<MapSearch authState={authState} view={this.view} />, this.parentContainer3);
            }

            this.view.ui.add(fullscreen, "top-left");
            if(!this.props.disableSearch === true)this.view.ui.add(search, { position: "top-left", index: 0 }) //only disable if an application geometry has already been submitted
            this.view.ui.add(homeBtn, "top-left");
            // this.view.ui.add(track, "top-left");

            // Top Right Parent Div
            this.parentContainer = document.createElement("div");
            this.parentContainer.className = "parentContainer";
            this.view.ui.add(this.parentContainer, "top-right");
            // ReactDOM.render(this.renderTopRightBar(baselayerid, map, has_webmap), this.parentContainer); //TOP RIGHT layers MS4,base, etc
            


            //only for application popup of permits
            const popupTemplate = {
                title: "{Name}",
                content: "{Description}"
             }
 
            // If we only have a single geometry object  
            if (geometry) {
                var coord;
                var temp;
                var coords = [];
                loadModules(["esri/geometry/support/webMercatorUtils"], { css: true }).then(([webMercatorUtils]) => {
                    if (geometry.type === "Polygon") {
                        geometry.coordinates[0].forEach((x) => {
                            coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                        });
                        temp = _this._getPolygonGrpahic("blue", coords, _this.view, Graphic);
                        _this.tempGraphicsLayer.add(temp);
                    } else if (geometry.type === "MultiPolygon") {
                        geometry.coordinates.forEach(function (c) {
                            coords = [];
                            c[0].forEach((x) => {
                                coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                            });
                            temp = _this._getPolygonGrpahic("blue", coords, _this.view, Graphic);
                            _this.tempGraphicsLayer.add(temp);
                        });
                    } else if (geometry.type === "LineString") {
                        geometry.coordinates.forEach((x) => {
                            coords.push(webMercatorUtils.lngLatToXY(x[0], x[1]));
                        });
                        temp = _this._getLineGrpahic("blue", coords, _this.view, Graphic);
                        _this.tempGraphicsLayer.add(temp);
                    } else if (geometry.type === "MultiLineString") {
                        geometry.coordinates.forEach((x) => {
                            coords = [];
                            x.forEach((l) => {
                                coords.push(webMercatorUtils.lngLatToXY(l[0], l[1]));
                            });
                            temp = _this._getLineGrpahic("blue", coords, _this.view, Graphic);
                            _this.tempGraphicsLayer.add(temp);
                        });
                    } else if (geometry.type === "MultiPoint") {
                        geometry.coordinates.forEach(function (c) {
                            coord = webMercatorUtils.lngLatToXY(c[0], c[1]);
                            temp = _this._getPointGrapicWeb("blue", coord[0], coord[1], _this.view, Graphic);
                            _this.tempGraphicsLayer.add(temp);
                        });
                    } else {
                        coord = webMercatorUtils.lngLatToXY(geometry.coordinates[0], geometry.coordinates[1]);
                        
                        //to show application location that user entered 
                        let tempattributes = {
                            Name: "Application Entered Location",
                            Description: `Street: ${_this.props.application.project_address}, City: ${_this.props.application.project_city}, Postal: ${_this.props.application.project_zip}<br/>
                            Review Project Address and City below`
                        }
                        temp = _this._getPointGrapicWeb("blue", coord[0], coord[1], _this.view, Graphic, tempattributes, popupTemplate);
                        _this.tempGraphicsLayer.add(temp);
                    }
                    _this.view.goTo({
                        target: _this.tempGraphicsLayer.graphics.items, 
                        zoom: 14
                    });
                });
            }

        
            if (cityID !== "14" && cityID !== "12"){ //dont allow ninemile, riley unless we redo their setup
                // MAP Editing 
                this.parentContainer2 = document.createElement("div");
                this.view.ui.add(this.parentContainer2, "bottom-right");
            
                ReactDOM.render(
                    <MuiThemeProvider theme={theme}>
                        <MapEditing
                            map={map}
                            GraphicsLayer={GraphicsLayer} 
                            readOnly={this.props.disableSearch}
                            setDrawingFeature={this.props.setDrawingFeature}
                            Graphic={Graphic}
                            _getPointGrapicWeb={this._getPointGrapicWeb}
                            _getLineGrpahic={this._getLineGrpahic}
                            _getPolygonGrpahic={this._getPolygonGrpahic}
                            webMercatorUtils={webMercatorUtils}
                            view={this.view}
                        />
                    </MuiThemeProvider>,
                    this.parentContainer2
                );
            }
               
        }).then((e)=>{
            var __this = this;
            __this.props.setDialogLoading(false)
        });
 
    }
 
    componentWillUnmount() {
        if (this.view) {
            this.view.destroy();
        }
    }
  

    _getPointGrapicWeb = (color, x, y, view, Graphic, attributes = {}, popupTemplate={}) => {
        var point = {
            type: "point",
            x: x,
            y: y,
            spatialReference: view.spatialReference,
        };

        var markerSymbol = {
            type: "simple-marker",
            color: color,
            width: 20,
            outline: {
                color: [255, 255, 255],
                width: 2,
            },
        };
        return new Graphic({
            geometry: point,
            symbol: markerSymbol,
            attributes: {
                key: performance.now(),
                ...attributes,
            },
            popupTemplate:{
                ...popupTemplate
            }
        });
    };

    _getLineGrpahic = (color, vertices, view, Graphic, attributes = {}) => {
        return new Graphic({
            geometry: {
                type: "polyline",
                paths: vertices,
                spatialReference: view.spatialReference,
            },
            symbol: {
                type: "simple-line",
                color: color,
                width: 5,
                cap: "round",
                join: "round",
            },
            attributes: {
                key: performance.now(),
                ...attributes,
            },
        });
    };

    _getPolygonGrpahic = (color, vertices, view, Graphic, attributes = {}) => {
        var rgb = "0, 0, 255";
        if (color === "gray") rgb = "128, 128, 128";
        if (color === "red") rgb = "255, 0, 0";
        return new Graphic({
            geometry: {
                type: "polygon",
                rings: vertices,
                spatialReference: view.spatialReference,
            },
            symbol: {
                type: "simple-fill",
                style: "solid",
                color: "rgba(" + rgb + ", 0.2)",
                outline: {
                    color: "rgba(" + rgb + ", 1)",
                    width: 1,
                },
            },
            attributes: {
                key: performance.now(),
                ...attributes,
            },
        });
    };

    //is triggered from the application fields of address, city & zip with a button  (NINEMILE, riley APP)
    createPoint =(address,city,zip)=>{
        const _this = this;
  
        if(address && city){
            fetch(`https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?token=AAPKf2e3a0e95055432995c143c76ec9d7acolbgIHxUiuqnTzAJN-soT9O7nlVATKm_6FrquwAVqfTuXBi0QAUuHui-C7NqPl7j&f=pjson&singleLine=${address},${city},MN&forStorage=false&maxResults=1`)
            .then(resp=> resp.json())
            .then(data=>{
               
                if (data && data.candidates.length > 0){
                   let x = data['candidates'][0]['location']['x']
                   let y = data['candidates'][0]['location']['y']
                    
                   //to show application location that user entered 
                    let tempattributes = {
                        Name: "Application Entered Location",
                        Description: `Street: ${address}, City: ${city}, Zip: ${zip} <br/>
                        Review Project Address and City below`
                    }
                     //only for application popup of permits
                    let popupTemplate = {
                        title: "{Name}",
                        content: "{Description}"
                    }
                    _this.tempGraphicsLayer.graphics.removeAll()
                    loadModules(
                        ["esri/Graphic","esri/geometry/support/webMercatorUtils"],
                        { css: true }
                    ).then(([Graphic,webMercatorUtils]) => {
                        let coord = webMercatorUtils.lngLatToXY(x, y); //this converts lat/long to X & Y
                        let temp = _this._getPointGrapicWeb("blue",coord[0],coord[1], _this.view, Graphic, tempattributes, popupTemplate);
                        _this.tempGraphicsLayer.add(temp); 
                        

                        //zoom to the point
                        _this.view.goTo({
                            target: temp, 
                            zoom: 14
                        });
                        _this.props.setPoint() //turn flagoff in app to not create a point anymore
                    })

                    let tempGeo = {longitude:x, latitude:y}
                    let tempAddress = {StAddr:address, City:city, Postal:zip}
                    _this.props.saveAddress(tempGeo, tempAddress)   //check for within boundry 
                }

            })
        }
    }

    render() {
        const { classes, viewOnly, address, city, zip, createPoint, setNull } = this.props;
        if(createPoint){ //only with address,city/zip from outside fields (NINEMILE PERMIT APP)
            this.createPoint(address,city,zip)
        }
   
        return <div id="ms4map" className={viewOnly ? classes.smallHeight : setNull ? classes.height : classes.fullHeight} ref={this.mapRef} />;
    }
}

BaseMapControlApplication = connect(
    (state, ownProps) => ({
        navState: state.nav,
        authState: state.auth,
    }),
    {
        ...navActions,    
    }
)(BaseMapControlApplication);

export default withStyles(styles)(withRouter(BaseMapControlApplication));

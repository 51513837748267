import React, { Component } from "react";
import classNames from "classnames";
import { FormField } from "react-form";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withStyles, Typography } from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";

const styles = (theme) => ({
    root: {
        display: "flex",
        color: "red",
    },
    label: {
        color: "red",
    },
    group: {
        margin: "0",
    },
    fullWidth: {
        width: "100%",
    },
    horizontal: {
        margin: "0",
        display: "inline",
    },
    groupName: {
        color: "#212529",
    },
    height: {
        marginTop: -8,
        marginBottom: -4,
    },
    nowrap: {
        //whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "100%",
        "& label": {
            color: "red",
        },
    },
    error: {
        "& h3": {
            // No way to access actual text - probably because typography tag is being passed as label
            color: "#be0000",
        },
        "& svg": {
            // Keep color on help icon
            color: "rgba(0, 0, 0, 0.87)",
        },
    },
});

class RadioGroupWrapper extends Component {
    /*handleChange = (event, value) => {
        const { fieldApi, eventHandle, name } = this.props;
        const { setValue, setTouched } = fieldApi;
        if (value === "true") value = true;
        if (value === "false") value = false;

        setValue(value);
        setTouched();

        if (eventHandle) eventHandle(value, name);
    };*/
    handleClick = (event) => {
        const { fieldApi, eventHandle, name } = this.props;
        const { setValue, setTouched, getValue } = fieldApi;
        var value = event.target.value;
        if (value === "true") value = true;
        if (value === "false") value = false;

        var previous_value = getValue()

        if (previous_value === value) {
            value = ""
        }

        setValue(value);
        setTouched();

        if (eventHandle) eventHandle(value, name);
    };

    render() {
        const { fieldApi, label, children, options, classes, required, alignment, fullWidth, style, disabled, color, ...rest } = this.props;

        const { getValue, getError } = fieldApi;

        var value = getValue(),
            error = getError(),
            hasError = !!error;

        // FIXME: Material UI requires string values
        // Radio values are returned from API as boolean
        if (value === true) value = "true";
        if (value === false) value = "false";

        return (
            <div>
                <FormControl component="fieldset" style={style} error={error && true} className={classNames(fullWidth && classes.fullWidth)}>
                    <FormLabel style={{ display: "inline-flex" }} classes={{ error: classes.error }} required={required}>
                        <Typography title={label ? label : null} className={classes.nowrap} style={{ marginTop: -4 }}>
                            {label || children}
                        </Typography>
                    </FormLabel>
                    <RadioGroup
                        className={classNames(alignment ? classes.horizontal : classes.group, classes.height)}
                        value={value}
                        //onChange={this.handleChange}
                        {...rest}>
                        {options.map((object) => (
                            <FormControlLabel
                                key={object.label}
                                disabled={object.disabled || disabled}
                                value={object.value}
                                control={<Radio onClick={this.handleClick} color="primary" style={{ color: object.color }} />}
                                label={object.label}
                                title={object.label}
                                className={classes.nowrap}
                                onClick={(e) => e.stopPropagation()}
                                classes={{ label: object.color ? classes.label : null }} //override color for label when lables isnt in selection list
                            />
                        ))}
                    </RadioGroup>
                    {error && <FormHelperText style={{ marginTop: 0 }}>{error}</FormHelperText>}
                    {required && !hasError && <FormHelperText style={{ marginTop: -1 }}>Required</FormHelperText>}
                </FormControl>
                <input //use to allow required to come up on screen
                    // Not sure if display none will work but its taking up way too much room otherwise
                    tabIndex={-1}
                    value={value === null && ""}
                    style={{ display: "none" }}
                    required={required}
                    readOnly={true}
                />
            </div>
        );
    }
}
RadioGroupWrapper.displayName = "RadioGroupWrapper";
const FppRadioGroup = FormField(RadioGroupWrapper);

export default withStyles(styles)(FppRadioGroup);

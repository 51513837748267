import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import Accordion from "@material-ui/core/Accordion";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import DialogContentText from "@material-ui/core/DialogContentText";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import ViewIcon from "@material-ui/icons/Visibility";
import Close from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import LockIcon from "@material-ui/icons/Lock";
import StepLabel from "@material-ui/core/StepLabel";
// import EditIcon from "@material-ui/icons/Edit"; 
// import AddIcon from "@material-ui/icons/Add";
// import Paper from "@material-ui/core/Paper";
// import { evaluate } from "mathjs";
// import NumberFormat from "react-number-format";

import WarningDialog from "../../common/WarningDialog";
import RadioGroup from "../../common/RadioGroup";
import AppContainerApplication from "../../common/AppContainerApplication";
import TextField from "../../common/TextField";
import Select from "../../common/Select";
import CheckboxGroup from "../../common/CheckboxGroup";  
import FileInput from "../../common/FileInput";
import Checkbox from "../../common/Checkbox";
// import Subquestion from "../../common/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import { createSelector } from "../../common/orm";
// import states from "../../common/statesmn.json";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import CustomTableCell from "../../common/TableCell";
import { ApplicationRecord, ApplicationRecordStatus, RpbcwdStewardshipGrant, RpbcwdStewardshipGrantAttachment, 
    RpbcwdStewardshipGrantExpense, RpbcwdStewardshipGrantAnnualAttachment, RpbcwdStewardshipGrantAnnualReporting } from "../../page/models";
import BaseMapControlApplication from "../esri/BaseMapControlApplication";
import ReactSelect from "../../common/ReactSelect";
 


const styles = (theme) => ({
    deleteWidth: {
        minWidth: 34,
        width: 34,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 2,
    },
    centerAlign: {
        textAlign: "center",
    },
    centerAlign2: {
        textAlign: "center",
        display: "inline-block",
        width: "100%",
    },
    nowrap: {
        whiteSpace: "nowrap",
    },
    rightAlign: {
        textAlign: "right",
        marginRight: 15,
    },
    flex: {
        flex: 1,
    },
    printDivider: {
        height: 8,
        backgroundColor: "rgba(0, 0, 0, 0.42)",
        marginTop: 16,
        marginBottom: 8,
    },
    dialogMin: {
        minWidth: 400,
    },
    minWidth: {
        minWidth: 600,
    },
    root: {
        flexBasis: "100%",
        maxWidth: "100%",
    },
    btnroot: {
        color: "#9e9e9e",
    },
});

function MAKE_OPTIONS(table) {
    return table.map((row) => ({
        label: row,
        value: row,
        linkhref: row.linkhref,
        linkname: row,
        help:row.help
    }));
}
// function MAKE_OPTIONS_LINK(table) {
//     return table.map((row) => ({
//         label: row.label,
//         value: row.value,
//         linkhref: row.linkhref,
//         linkname: row.linkname,
//         help:row.help
//     }));
// }


const getApplication = createSelector(
    (state, ownProps) => ownProps.match.params["hash"],
    (session, hash) => {
        return session.RpbcwdStewardshipGrant.filter((p) => p.hash === hash).toModelArray()[0] 
      
    }
);
 

class RpbcwdGrant_ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0, 
            formKey: null,
            order: "desc",
            orderBy: null,
            temp_contacts: [],
            attachmentDialogOpen: false,
            attachmentEditing: null,
            attachmentFormKey: null,
            attachmentOrder: "desc",
            attachmentOrderBy: null,
            temp_attachments: [],
            loading: false,
            new_save: false,
            new_save2: false,
            mainFormKey: null,
            entryError: false,
            thegeo:null,
            primaryContact:false,
            dialogLoading:false,
            alerts:"",
            outOfDistrict:false,
            address:null,
            city:null,
            zip:null,
            createPoint:false,
            hasAddress:true,
            waterbody:false,
            projectType:[],
            attachmentType:null,
            expenseEditing:null,
            reimbursementEditing:null,
            tempReimbursements:[],
            reimbursementDialogOpen:false,
            reimbursementFormKey: null,
            expenseDialogOpen:false,
            expenseFormKey:null,
            calculation: {"requested": 0, "budget": 0},
            // consentFromOwner:false,
            saveDialogOpen:false, 
            submittedSave:false,
            checkWhatFields:null,
            saveInfo:{owner_first_name:null, owner_last_name:null, owner_email:null}, 
            incompleteSave:false,  
            maxFunds:0,
            alertOpen:false,
            priorErrorsToClear:{},
            mainExplain:false,
            showProgressExtra:false,
            temp_reimbursement_attachments: [],
            attachmentReimbursementEditing: null,
            attachmentReimbursementDialogOpen:false,
            attachmentReimbursementFormKey: null, 
            new_save3:false,
            new_save4:false, 
            appData:null, 
            historicAnnualReporting:[],
            historicAnnualAttachment:[],
            projTypeOptions:[]
     
        };
    } 
    // componentDidUpdate(prevProps, newProps){
    //     console.log("hit", prevProps, newProps)
    // }

    componentDidMount() {
        const { match, ormRpbcwdStewardshipGrantLoadDetailChild, ormRpbcwdStewardshipGrantAttachmentLoadDetailChild, 
            ormRpbcwdStewardshipGrantAnnualReportingLoadDetailChild, ormRpbcwdStewardshipGrantAnnualAttachmentLoadDetailChild} = this.props;
        var component = this;
        // const {temp_attachments} = this.state
        // let newAttachments = temp_attachments
        
        if (match.params && match.params.hash) {
       
            ormRpbcwdStewardshipGrantLoadDetailChild(match.params.hash, function (hmm) {
               
                // let maintenceText = hmm[0].requested_funds <= 5000 ? "I agree to maintain this project for five years following installation."
                //  : "I agree to maintain this project for ten years following installation." 

                // //custom text for help bubble under financial 'requested funds field
                // let tempFunds = 0
                // let grantType = hmm[0].grant_type
                // if (hmm[0].rules && (hmm[0].rules === 'Native Habitat Restoration' || hmm[0].rules === 'Native Planting')){
                //     if (grantType){
                //         if(grantType === 'Residential'){
                //             tempFunds = 3000.00
                //         }else if (grantType === 'School' || grantType === 'Nonprofit' || grantType === 'Business' || grantType === 'City'){
                //             tempFunds = 10000.00
                //         }else if (grantType === 'Association'){
                //             tempFunds = 5000.00
                //         } 
                //     } 
                // }else{ //all other grant types
                //     if (grantType){
                //         if(grantType === 'Residential'){
                //             tempFunds = 5000.00
                //         }else if (grantType === 'School' || grantType === 'City' || grantType === 'Nonprofit' || grantType === 'Business'){
                //             tempFunds = 50000.00
                //         }else if (grantType === 'Association'){
                //             tempFunds = 20000.00
                //         }   
                //     }    
                // } 

                //set project options based on what was set before
                let projTypeOptions = []
                let appType = hmm[0].grant_type
                if(appType === 'Residential'){
                    projTypeOptions = [
                        "Native Plants", "Turf Alternative", "Rain Barrel", "Habitat Restoration", "Lake Shore Restoration", "Waterbody Buffer", "Stormwater BMP", "Shoreland Health"
                    ]
                }else if (appType === 'Non-profit' || appType === 'Non-profit' || appType === 'Government' || appType === 'Business' || appType === 'School'){
                    projTypeOptions = [
                        "Native Plants", "Turf Alternative",  "Habitat Restoration", "Lake Shore Restoration", "Waterbody Buffer", "Stormwater BMP",  
                    ]
                }
               
                component.setState({appData:hmm[0], hasAddress: !hmm[0].doesnt_have_address, projectType:hmm[0].rules, projTypeOptions, waterbody: hmm[0].wetland_present,  mainExplain:hmm[0].main_explain , mainFormKey: window.performance.now() })
            });
           
            ormRpbcwdStewardshipGrantAttachmentLoadDetailChild(match.params.hash, function (data) { 
                // data.forEach(att=>{ // we do this as we need the images that were saved previously 'id' key to not resave them again. 
                //         let temp = {}
                //         temp['id'] = 'blabla'
                //         temp['file'] = att.file
                //         temp['name'] = att.name
                //         temp['_type'] = att._type
                //         newAttachments.push(temp) 
                //     }) 
                component.setState({ temp_attachments: data });
            })  
          
            ormRpbcwdStewardshipGrantAnnualReportingLoadDetailChild(match.params.hash, function (data) {  
                component.setState({ historicAnnualReporting: data });  
            })
            ormRpbcwdStewardshipGrantAnnualAttachmentLoadDetailChild(match.params.hash, function (data) {  
                component.setState({ historicAnnualAttachment: data });  
            })              
        }
        if (match.params && match.params.print) {
            component.setState({ activeStep: "PRINT" });
        }
    }


    submitApplication = (values, what) => {
        const { 
            ormApplicationRecordCreate,
            ormApplicationRecordStatusCreate,
            ormRpbcwdStewardshipGrantCreate,
            ormRpbcwdStewardshipGrantAttachmentCreate, 
            ormRpbcwdStewardshipGrantUpdatePublic,
            ormRpbcwdStewardshipGrantAnnualReportingCreate,
            ormRpbcwdStewardshipGrantAnnualAttachmentCreate ,  
        } = this.props;
        const { temp_attachments, thegeo, alerts, checkWhatFields, temp_reimbursement_attachments, appData  } = this.state;

        const _this = this;
        
        // if(!alerts && what !== 'calcresave') this.setState({ loading: true }); //used for attachment alerts to not go into loading
        this.setState({ loading: true });

        let created = 0
        let totalAttachments =  temp_attachments.length 
        let hasntSaved = false

        // Editing from a prior save but hasnt been submitted onto the internal ms4front side
        if (appData) { 
            if(checkWhatFields === "yearly"){//annual report
                
                let _this = this
                ormRpbcwdStewardshipGrantAnnualReportingCreate({
                    rpbcwdstewardshipgrant_id:values.id,
                    ...values
                }).then(rec=>{
                    let total = temp_reimbursement_attachments.length
                    let add = 0 
                    temp_reimbursement_attachments.forEach(r=>{
                        ormRpbcwdStewardshipGrantAnnualAttachmentCreate({
                            rpbcwdstewardshipgrantannualreporting_id:rec.id,
                            ...r 
                        }).then(a=>{
                            add += 1
                            if(add === total)_this.setState({ loading: false, new_save4: true });
                        }) 
                    })
                    //check for if there werent attachments or expenses just exit
                    if(add === total)_this.setState({ loading: false, new_save4: true });
                })
 
            }else if (checkWhatFields === 'Site Visit Completed'){//FULL FILL OUT OF THE APPLICATION STEP 3-9 
            
                temp_attachments.forEach(function (tc) { 
                    if (!tc.id){//only save if it hasnt been saved before 
                        ormRpbcwdStewardshipGrantAttachmentCreate({
                            rpbcwdstewardshipgrant_id: appData.id,
                            ...tc,
                        }).then(()=>{
                            created++
                            if(created === totalAttachments && !hasntSaved){ // we save status or submit to pending only when all attachments are saved
                                ormApplicationRecordStatusCreate({
                                    applicationrecord: appData.apprec.id,
                                    status: "Application Submitted/Under Review"
                                }).then(statusrecord => {
                                    ormRpbcwdStewardshipGrantUpdatePublic({
                                        id: appData.id,
                                        ...values
                                    })
                                    // if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                    // else _this.setState({ loading: false, new_save: true });
                                    // hasntSaved = true
                                    _this.setState({ loading: false, new_save: true });
                                })
                            }
                        })   
                    }else{
                        created++
                        if(created === totalAttachments && !hasntSaved){ //we have to do this as we might have skipped saving a prior attachment not fond of but its needed to be in sync
                            ormApplicationRecordStatusCreate({
                                applicationrecord: appData.apprec.id,
                                status: "Application Submitted/Under Review"
                            }).then(statusrecord => {
                                ormRpbcwdStewardshipGrantUpdatePublic({
                                    id: appData.id,
                                    ...values
                                })
                                // if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                // else _this.setState({ loading: false, new_save: true });
                                // hasntSaved = true
                                _this.setState({ loading: false, new_save: true });
                            })
                        }
                    }
                }); 


                if (totalAttachments === 0){ //no attachements so save or submit right away
                    ormApplicationRecordStatusCreate({
                        applicationrecord: appData.apprec.id,
                        status: "Application Submitted/Under Review"
                    }).then(statusrecord => {
                        ormRpbcwdStewardshipGrantUpdatePublic({
                            id: appData.id,
                            ...values
                        })
                        // if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                        // else _this.setState({ loading: false, new_save: true });
                        _this.setState({ loading: false, new_save: true });
                    })
                }  
            }else if (checkWhatFields === 'Executed Agreement'){//step 10
            
                temp_attachments.forEach(function (tc) { 
                    if (!tc.id){//only save if it hasnt been saved before 
                        ormRpbcwdStewardshipGrantAttachmentCreate({
                            rpbcwdstewardshipgrant_id: appData.id,
                            ...tc,
                        }).then(()=>{
                            created++
                            if(created === totalAttachments && !hasntSaved){ // we save status or submit to pending only when all attachments are saved
                                ormApplicationRecordStatusCreate({
                                    applicationrecord: appData.apprec.id,
                                    status: "Project Complete Pending Reimbursement"
                                }).then(statusrecord => {
                                    ormRpbcwdStewardshipGrantUpdatePublic({
                                        id: appData.id,
                                        ...values
                                    })
                                    // if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                    // else _this.setState({ loading: false, new_save: true });
                                    // hasntSaved = true
                                    _this.setState({ loading: false, new_save: true });
                                })
                            }
                        })   
                    }else{
                        created++
                        if(created === totalAttachments && !hasntSaved){ //we have to do this as we might have skipped saving a prior attachment not fond of but its needed to be in sync
                            ormApplicationRecordStatusCreate({
                                applicationrecord: appData.apprec.id,
                                status: "Project Complete Pending Reimbursement"
                            }).then(statusrecord => {
                                ormRpbcwdStewardshipGrantUpdatePublic({
                                    id: appData.id,
                                    ...values
                                })
                                // if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                                // else _this.setState({ loading: false, new_save: true });
                                // hasntSaved = true
                                _this.setState({ loading: false, new_save: true });
                            })
                        }
                    }
                }); 


                if (totalAttachments === 0){ //no attachements so save or submit right away
                    ormApplicationRecordStatusCreate({
                        applicationrecord: appData.apprec.id,
                        status: "Project Complete Pending Reimbursement"
                    }).then(statusrecord => {
                        ormRpbcwdStewardshipGrantUpdatePublic({
                            id: appData.id,
                            ...values
                        })
                        // if(what === 'Save Not Completed') _this.setState({ loading: false, incompleteSave: true });
                        // else _this.setState({ loading: false, new_save: true });
                        _this.setState({ loading: false, new_save: true });
                    })
                }  
            }
            else if (checkWhatFields === 'Save Not Completed'){//standard save or submitted  
                temp_attachments.forEach(function (tc) { 
                    if (!tc.id){//only save if it hasnt been saved before 
                        ormRpbcwdStewardshipGrantAttachmentCreate({
                            rpbcwdstewardshipgrant_id: appData.id,
                            ...tc,
                        }).then(()=>{
                            created++
                            if(created === totalAttachments && !hasntSaved){ // we save status or submit to pending only when all attachments are saved
                                ormApplicationRecordStatusCreate({
                                    applicationrecord: appData.apprec.id,
                                    status: 'Save Not Completed' 
                                }).then(statusrecord => {
                                    ormRpbcwdStewardshipGrantUpdatePublic({
                                        id: appData.id,
                                        ...values
                                    })
                                    _this.setState({ loading: false, incompleteSave: true }); 
                                    hasntSaved = true
                                })
                            }
                        })   
                    }else{
                        created++
                        if(created === totalAttachments && !hasntSaved){ //we have to do this as we might have skipped saving a prior attachment not fond of but its needed to be in sync
                            ormApplicationRecordStatusCreate({
                                applicationrecord: appData.apprec.id,
                                status:'Save Not Completed' 
                            }).then(statusrecord => {
                                ormRpbcwdStewardshipGrantUpdatePublic({
                                    id: appData.id,
                                    ...values
                                })
                                _this.setState({ loading: false, incompleteSave: true }); 
                                hasntSaved = true
                            })
                        }
                    }
                }); 


                if (totalAttachments === 0){ //no attachements so save or submit right away
                    ormApplicationRecordStatusCreate({
                        applicationrecord: appData.apprec.id,
                        status: 'Save Not Completed'  
                    }).then(statusrecord => {
                        ormRpbcwdStewardshipGrantUpdatePublic({
                            id: appData.id,
                            ...values
                        })
                        _this.setState({ loading: false, incompleteSave: true });
                    })
                }  
            }
        } else {
            if(!alerts){
                // NEW INITIAL INQUIRY STEP 2 ONLY
                ormApplicationRecordCreate({
                    application: 18,
                }).then((ar) => { 
                    ormApplicationRecordStatusCreate({
                        applicationrecord: ar.id,
                        status: "Initial Inquiry"
                    }).then(bla=>{ 
                        ormRpbcwdStewardshipGrantCreate({
                            applicationrecord: ar.id,
                            geometry: thegeo ? thegeo : null,
                            ...values,
                        }).then((rpb) => {
                            if (rpb && rpb.error) {
                                this.setState({ entryError: true, loading: false });
                                fetch("/error/post", {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({
                                        error: JSON.stringify(values),
                                        error_info: rpb.error.toString(),
                                    }),
                                });
                            } else {  
                                _this.setState({ loading: false, new_save: true }); 
                            }
                        });
                    })
                });
            }
        }

        this.setState({checkWhatFields:null})
    };
  
    errorValidator = (values) => { 
        const {checkWhatFields, hasAddress, priorErrorsToClear}  = this.state;

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };      
        // const YesisRequired = (val) => {
        //     return val !== 'Yes' ? "Required" : null;
        // };
        const validateEmail = (email) => {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !re.test(email) ? "Invalid Email Address" : null;
        };
    
        var valObj = {}
        var alerts = "";
        var ai = 8;

        if (checkWhatFields === 'Initial Inquiry'){//check for initial inquiry fields
            if (!values.secondary_first_name){//property owner wasnt filled out make sure they have prop owner permisson
                if (values.primary_contact){ 
                    valObj = { 
                        owner_first_name: isRequired(values.owner_first_name),
                        owner_last_name: isRequired(values.owner_last_name),
                        owner_mailing_address: isRequired(values.owner_mailing_address),
                        owner_phone: isRequired(values.owner_phone),
                        owner_city: isRequired(values.owner_city),
                        owner_state: isRequired(values.owner_state),
                        owner_zip: isRequired(values.owner_zip),
                        owner_email: validateEmail(values.owner_email), 
                        
                        project_address: isRequired(values.project_address),
                        project_city: isRequired(values.project_city),
                        proj_interested: isRequired(values.proj_interested),
                        proj_desc: isRequired(values.proj_desc),
                        proj_loc: isRequired(values.proj_loc),  
                        onsite_visit: isRequired(values.onsite_visit),
                        site_time: isRequired(values.site_time), 
                    
                    }
                }else{ //not primary so make sure they fill out secondary
                    valObj = {
                        
                        owner_first_name: isRequired(values.owner_first_name),
                        owner_last_name: isRequired(values.owner_last_name),
                        owner_mailing_address: isRequired(values.owner_mailing_address),
                        owner_phone: isRequired(values.owner_phone),
                        owner_city: isRequired(values.owner_city),
                        owner_state: isRequired(values.owner_state),
                        owner_zip: isRequired(values.owner_zip),
                        owner_email: validateEmail(values.owner_email), 

                        secondary_first_name: isRequired(values.secondary_first_name),
                        secondary_last_name: isRequired(values.secondary_last_name),
                        secondary_mailing_address: isRequired(values.secondary_mailing_address),
                        secondary_phone: isRequired(values.secondary_phone),
                        secondary_city: isRequired(values.secondary_city),
                        secondary_state: isRequired(values.secondary_state),
                        secondary_zip: isRequired(values.secondary_zip),
                        secondary_email: validateEmail(values.secondary_email),
                        
                        project_address: isRequired(values.project_address),
                        project_city: isRequired(values.project_city),
                        proj_interested: isRequired(values.proj_interested),
                        proj_desc: isRequired(values.proj_desc),
                        proj_loc: isRequired(values.proj_loc),  
                        onsite_visit: isRequired(values.onsite_visit),
                        site_time: isRequired(values.site_time), 
                    }; 
                }
            }else{//property owner was filled out
                valObj = { 
                    owner_first_name: isRequired(values.owner_first_name),
                    owner_last_name: isRequired(values.owner_last_name),
                    owner_mailing_address: isRequired(values.owner_mailing_address),
                    owner_phone: isRequired(values.owner_phone),
                    owner_city: isRequired(values.owner_city),
                    owner_state: isRequired(values.owner_state),
                    owner_zip: isRequired(values.owner_zip),
                    owner_email: validateEmail(values.owner_email), 

                    secondary_first_name: isRequired(values.secondary_first_name),
                    secondary_last_name: isRequired(values.secondary_last_name),
                    secondary_mailing_address: isRequired(values.secondary_mailing_address),
                    secondary_phone: isRequired(values.secondary_phone),
                    secondary_city: isRequired(values.secondary_city),
                    secondary_state: isRequired(values.secondary_state),
                    secondary_zip: isRequired(values.secondary_zip),
                    secondary_email: validateEmail(values.secondary_email),
                    
                    project_address: isRequired(values.project_address),
                    project_city: isRequired(values.project_city),
                    proj_interested: isRequired(values.proj_interested),
                    proj_desc: isRequired(values.proj_desc),
                    proj_loc: isRequired(values.proj_loc),  
                    onsite_visit: isRequired(values.onsite_visit),
                    site_time: isRequired(values.site_time),
                
                }
            }
           
      
        } else if (checkWhatFields === 'Site Visit Completed'){
            valObj = { 
                grant_type: isRequired(values.grant_type),
                rules: isRequired(values.rules),
                bmp_type: values.rules === 'Stormwater BMP' ? isRequired(values.bmp_type) : '',
                prj_size: isRequired(values.prj_size),
                prj_loc: isRequired(values.prj_loc),
                prj_est_cost: isRequired(values.prj_est_cost),
                prj_inkind: ((values.grant_type === 'Residential' || values.grant_type === 'Non-profit') && values.rules.find(r=> r === 'Habitat Restoration' || r === 'Shoreline Restoration' || r === 'Stormwater BMP' || r === 'Waterbody Buffer' || r === 'Shoreland Health'))   ? isRequired(values.prj_inkind) : '',
                prj_est_date: isRequired(values.prj_est_date), 
                prj_time: isRequired(values.prj_time), 
                prj_cond: isRequired(values.prj_cond), 
                prj_goal: isRequired(values.prj_goal), 
                prj_installer: isRequired(values.prj_installer),  
                native_grower: values.rules.find(r=> r === 'Native Plant' || r === 'Habitat Restoration' || r === 'Shoreline Restoration' || r === 'Waterbody Buffer' || r === 'Shoreland Health') ? isRequired(values.native_grower) : '',  
                desc_main: isRequired(values.desc_main), 
                signature: isRequired(values.signature) 

            
            } 
        
        } else if (checkWhatFields === 'Executed Agreement'){
            valObj = { 
                tot_prj_cost: isRequired(values.tot_prj_cost),
                prj_summary: isRequired(values.prj_summary),
                recpt: isRequired(values.prj_summary),
                pref_reimb: isRequired(values.pref_reimb),  
            } 
        }

        else if (checkWhatFields === 'yearly'){
            valObj = { 
                maint: isRequired(values.maint),
                prj_going: isRequired(values.prj_going),
                visit: isRequired(values.visit),
                phone: isRequired(values.phone),  
                images: isRequired(values.images), 
            } 
        }

        let attachmentsNeeded = []
            
        if (valObj["owner_first_name"] === "Required") {
            alerts += "Applicant First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_last_name"] === "Required") {
            alerts += "Applicant Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_mailing_address"] === "Required") {
            alerts += "Applicant Mailing Address (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_city"] === "Required") {
            alerts += "Applicant City (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_state"] === "Required") {
            alerts += "Applicant State (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_zip"] === "Required") {
            alerts += "Applicant Zip (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_phone"] === "Required") {
            alerts += "Applicant Phone (Step 2)\n";
            ai = 1;
        }
        if (valObj["owner_email"] === "Invalid Email Address") {
            alerts += "Applicant Email (Step 2)\n";
            ai = 1;
        } 

        if (valObj["secondary_first_name"] === "Required") {
            alerts += "Property Owner First Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_last_name"] === "Required") {
            alerts += "Property Owner Last Name (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_mailing_address"] === "Required") {
            alerts += "Property Owner Mailing Address (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_city"] === "Required") {
            alerts += "Property Owner City (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_state"] === "Required") {
            alerts += "Property Owner State (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_zip"] === "Required") {
            alerts += "Property Owner Zip (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_phone"] === "Required") {
            alerts += "Property Owner Phone (Step 2)\n";
            ai = 1;
        }
        if (valObj["secondary_email"] === "Invalid Email Address") {
            alerts += "Property Owner Email (Step 2)\n";
            ai = 1;
        } 
        


        if (valObj["project_address"] === "Required") {
            alerts += "Project Address (Step 2)\n";
            ai = 1;
        }
        if (valObj["project_city"] === "Required") {
            alerts += "Project City (Step 2)\n";
            ai = 1;
        }
        if (valObj["proj_interested"] === "Required") {
            alerts += "Project Interested (Step 2)\n";
            ai = 1;
        }

        if (valObj["proj_desc"] === "Required") {
            alerts += "Project Description (Step 2)\n";
            ai = 1;
        }
        if (valObj["proj_loc"] === "Required") {
            alerts += "Project Location (Step 2)\n";
            ai = 1;
        }
        if (valObj["onsite_visit"] === "Required") {
            alerts += "Project Onsite Visit (Step 2)\n";
            ai = 1;
        }
        if (valObj["site_time"] === "Required") {
            alerts += "Preferred Site Visit Time (Step 2)\n";
            ai = 1;
        }
 
        //#3
        if (valObj["grant_type"] === "Required") {
            alerts += "Applicant Type(Step 3)\n";
            ai = 2;
        }
        if (valObj["rules"] === "Required") {
            alerts += "Project Type (Step 3)\n";
            ai = 2;
        }    
        if (valObj["bmp_type"] === "Required") {
            alerts += "BMP Type (Step 3)\n";
            ai = 2;
        }    
        //#4
        if (valObj["prj_size"] === "Required") {
            alerts += "Project Size (Step 4)\n";
            ai = 3;
        }
        if (valObj["prj_loc"] === "Required") {
            alerts += "Project Location (Step 4)\n";
            ai = 3;
        }    
        if (valObj["prj_est_cost"] === "Required") {
            alerts += "Estimated Cost (Step 4)\n";
            ai = 3;
        }   
        if (valObj["prj_inkind"] === "Required") {
            alerts += "Inkind Credit (Step 4)\n";
            ai = 3;
        }
        if (valObj["prj_est_date"] === "Required") {
            alerts += "Estimated Start Date (Step 4)\n";
            ai = 3;
        }    
        if (valObj["prj_time"] === "Required") {
            alerts += "How many Months (Step 4)\n";
            ai = 3;
        }    
        if (valObj["prj_cond"] === "Required") {
            alerts += "Describe current condition (Step 4)\n";
            ai = 3;
        }
        if (valObj["prj_goal"] === "Required") {
            alerts += "What is your goals (Step 4)\n";
            ai = 3;
        }    
        if (valObj["prj_installer"] === "Required") {
            alerts += "Who will install (Step 4)\n";
            ai = 3;
        }  
        if (valObj["native_grower"] === "Required") {
            alerts += "Name of grower/nursery (Step 4)\n";
            ai = 3;
        }    
        if (valObj["desc_main"] === "Required") {
            alerts += "Describe how you will maintain (Step 4)\n";
            ai = 3;
        }    
          
        
            //native planting 
        if (valObj["native_eco"] === "Required") {
            alerts += "Size of area (Step 5)\n";
            ai = 4;
        } 
        if (valObj["native_requirements"] === "Required") {
            alerts += "Reimbursement only to native plants (Step 5)\n";
            ai = 4;
        } 
    
        // if(attachmentsNeeded.length > 0){
        //     attachmentsNeeded.forEach(at=>{ 
        //         alerts += `${at} File Required (Step 5)\n`;
        //         ai = 4
        //     })
        // } 


        if (valObj["ed_share"] === "Required") {
            alerts += "How will you share (Step 7)\n";
            ai = 7
        }
        if (valObj["ed_can_share"] === "Required") {
            alerts += "Can we share you project (Step 7)\n";
            ai = 7
        }
        if (valObj["ed_highlight"] === "Required") {
            alerts += "Education highlight (Step 7)\n";
            ai = 7
        }

        if (valObj["main_report"] === "Required") {
            alerts += "Maintenance report (Step 8)\n";
            ai = 8
        }
        if (valObj["main_maintain"] === "Required") {
            alerts += "Maintain maintain (Step 8)\n";
            ai = 8
        }
        if (valObj["main_agree"] === "Required") {
            alerts += "Maintain agree (Step 8)\n";
            ai = 8
        }
        if (valObj["signature"] === "Required") {
            alerts += "I agree (Step 9)\n";
            ai = 8
        }

        //closeout #10
        if (valObj["tot_prj_cost"] === "Required") {
            alerts += "Total Project Cost (Step 10)\n";
            ai = 9
        }
        if (valObj["prj_summary"] === "Required") {
            alerts += "Summarize Project (Step 10)\n";
            ai = 9
        }
        if (valObj["recpt"] === "Required") {
            alerts += "Receipts not related to project? (Step 10)\n";
            ai = 9
        }
        if (valObj["pref_reimb"] === "Required") {
            alerts += "Preferred reimbursement delivery (Step 10)\n";
            ai = 9
        }
     
            
        //annual # 11
        if (valObj["maint"] === "Required") {
            alerts += "Have you maintained? (Step 11)\n";
            ai = 10
        }
        if (valObj["prj_going"] === "Required") {
            alerts += "How is the project going? (Step 11)\n";
            ai = 10
        }
        if (valObj["visit"] === "Required") {
            alerts += "Would you like to schedule a visit? (Step 11)\n";
            ai = 10
        }
        if (valObj["phone"] === "Required") {
            alerts += "Provide most recent phone/email (Step 11)\n";
            ai = 10
        }
        if (valObj["images"] === "Required") {
                alerts += "I understand my images maybe used to promote (Step 11)\n";
                ai = 10 
        }
    
        if (alerts) {
            this.setState({ activeStep: ai, alerts: alerts, alertOpen:true, priorErrorsToClear:valObj, checkWhatFields:"" });
        }else{
             // occurs if they try to submit first memoized form then save only as has those fields as problems so we need to clear the keys else reactform wont submit
             if(JSON.stringify(priorErrorsToClear) !== '{}'){
                Object.keys(priorErrorsToClear).forEach(key=>{priorErrorsToClear[key] = null}) 
                this.setState({alerts:alerts, priorErrorsToClear})
                valObj = {...priorErrorsToClear}
            }
            // this.setState({alerts:alerts, priorErrorsToClear:valObj})
        } 
  
        this.setState({submittedSave:false })
    

        return valObj;
    };

    errorValidatorAttachment = (values) => {

        const isRequired = (val) => {
            return !val ? "Required" : null;
        };
        var valObj = {
            file: isRequired(values.file),
            name: isRequired(values.name),
        };

        return valObj;
    };
 
    errorValidatorReimbursement = (values) => {
        const {appData } = this.state
        
        const checkTotal = (val) => { 
            if(val){ 
                let priorRequested = this.formApi.getValue('total_reimbursement')
                return (parseFloat(priorRequested) + parseFloat(val)) > appData.approved_funds ? `You were awarded a $${appData.approved_funds} cost share grant. You cannot request reimbursement for more than this amount.` : null;
            } 
        };

        var valObj = { 
            requested_amount: checkTotal(values.requested_amount), 
        };

        return valObj;
    };

    addReimbursementAttachment = (values) => {
        var { attachmentReimbursementEditing, temp_reimbursement_attachments} = this.state;

        if (!attachmentReimbursementEditing) {
            temp_reimbursement_attachments.push(values);
            this.setState({ temp_reimbursement_attachments});
        } else {
            var temp = temp_reimbursement_attachments.filter((tc) => tc !== attachmentReimbursementEditing);
            temp.push(values);
            this.setState({ temp_reimbursement_attachments: temp });
        }
          
        this.setState({ attachmentReimbursementDialogOpen: false });
    };

    addAttachment = (values) => {
        var { attachmentEditing, temp_attachments, attachmentType } = this.state;

        //for images to show under correct areas
        if (attachmentType && attachmentType === 'Maintenance Proposal'){
            values['_type'] = 'Maintenance Proposal'
        }
        
        else if (attachmentType && attachmentType === 'Closeout Photo'){
            values['_type'] = 'Closeout Photo'
        }
        else if (attachmentType && attachmentType === 'Closeout Receipt'){
            values['_type'] = 'Closeout Receipt'
        }
        // else if (attachmentType && attachmentType === 'Native Planting'){
        //     values['_type'] = 'Native Planting'
        // }
        // else if (attachmentType && attachmentType === 'Pervious Pavement'){
        //     values['_type'] = 'Pervious Pavement'
        // }
        // else if (attachmentType && attachmentType === 'Chloride Reduction'){
        //     values['_type'] = 'Chloride Reduction'
        // }
        // else if (attachmentType && attachmentType === 'Green Roof'){
        //     values['_type'] = 'Green Roof'
        // }

        if (!attachmentEditing) {
            temp_attachments.push(values);
            this.setState({ temp_attachments: temp_attachments });
        } else {
            var temp = temp_attachments.filter((tc) => tc !== attachmentEditing);
            temp.push(values);
            this.setState({ temp_attachments: temp });
        }
          
        this.setState({ attachmentDialogOpen: false, attachmentType:null });
    };
  
    handleRequestSort2 = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.state.temp_attachments.sort((a, b) => (b[orderBy].toUpperCase() < a[orderBy].toUpperCase() ? -1 : 1))
            : this.state.temp_attachments.sort((a, b) => (a[orderBy].toUpperCase() < b[orderBy].toUpperCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleNext = (index) => {
        const { activeStep } = this.state;
       

        if (index || index === 0) {
            this.setState({ activeStep: index, dialogLoading: index === 2 && activeStep !== 2 ? true : false });
        } else if (activeStep < 10) { 
            this.setState({ activeStep: activeStep + 1 }); 
        }
        // else if(activeStep === 8) {//first applying
        //     this.formApi.submitForm();
        // }else if (activeStep === 9){ 
        //     this.formApi.submitForm("yearly");//yearly
        // }
    };

    //save the geometry from the map
    saveAddress=(geometry, address)=>{
   
        this.formApi.setValue("project_address",address.StAddr)
        this.formApi.setValue("project_city",address.City) //if city isnt within list it wont show on dropdown
        this.formApi.setValue("project_zip",address.Postal)
    
        let geojson = {
            type: "Point",
            coordinates: [geometry.longitude,geometry.latitude]
        };

        //check if within boundary
        fetch(`
            https://services7.arcgis.com/mPwPtApXJIVecEVd/arcgis/rest/services/RBPBWD_boundary/FeatureServer/0/query?where=1=1&objectIds=&time=&geometry=${geometry.longitude},${geometry.latitude}
            &geometryType=esriGeometryPoint&inSR=4326&spatialRel=esriSpatialRelIntersects&resultType=none&distance=0.0&units=esriSRUnit_Meter&relationParam=&returnGeodetic=false&outFields
            =&returnGeometry=true&returnCentroid=false&featureEncoding=esriDefault&multipatchOption=xyFootprint&maxAllowableOffset=&geometryPrecision
            =&outSR=&defaultSR=&datumTransformation=&applyVCSProjection=false&returnIdsOnly=false&returnUniqueIdsOnly=false&returnCountOnly=false&returnExtentOnly=false&returnQueryGeometry=false
            &returnDistinctValues=false&cacheHint=false&orderByFields=&groupByFieldsForStatistics=&outStatistics=&having=&resultOffset=&resultRecordCount=&returnZ=false&returnM=false
            &returnExceededLimitFeatures=true&quantizationParameters=&sqlFormat=none&f=pgeojson&token=`,
            {
                method : "GET"

            })
            .then(resp=> resp.json())
            .then(data=>{
               if(data.features.length === 0){
                this.setState({outOfDistrict:true})
               }
        })
        this.setState({thegeo:geojson})
  
    }

    //check address and city for proper geometry point
    // checkAddress = (e) =>{ 
    //     const {formApi} = this;
   
    //     let city = formApi.getValue("project_city")
    //     let address = formApi.getValue("project_address")
    //     let zip = formApi.getValue("project_zip")
    //     if (city && address && zip) this.setState({city,address, zip, createPoint:true})

    // }

    setPoint=()=>{
        this.setState({createPoint:false})
    }

    setDialogLoading = (e) =>{
        this.setState({dialogLoading:e})
    }
  
 
    defaultValues =()=>{
        const {appData} = this.state
        
        let obj = {} 
        obj = {...appData}
        if(appData && appData.apprec.status === 'open'){ // add inprogress reporting keys as we are on that step   
            obj['check_made_to'] = '' 
            obj['check_mailed_to'] = ''
            obj['summary'] = ''
            obj['final_request'] = 'No'
            obj['describe_project'] = '' 
            obj['project_outcome'] = ''
            obj['project_challenges'] = ''
            obj['project_longevity'] = '' 
            obj['total_reimbursement'] = ''
            obj['total_match'] = ''
            obj['attachment'] = ''

        }
        else if(appData && appData.apprec.status === 'closed'){ // add closed reporting keys as we are on that step   
            obj['annual_summary'] = '' 
            obj['summary_main'] = ''
            obj['main_concerns'] = ''
            obj['comments'] = ''  
            obj['attachment'] = ''
        }
        return obj
    }

  
   

    render() {
        const { classes, ormRpbcwdStewardshipGrantAttachmentDelete} = this.props;
        const {
            activeStep,
            attachmentDialogOpen,
            attachmentEditing,
            attachmentFormKey, 
            temp_attachments,
            loading,
            new_save,
            mainFormKey,
            new_save2,
            // new_save3,
            new_save4,
            entryError,
            primaryContact,
            dialogLoading,
            outOfDistrict,
            city,
            address,
            zip,
            createPoint,
            hasAddress, 
            projectType,
            attachmentType,
            expenseDialogOpen, 
            expenseEditing,
            expenseFormKey, 
            // consentFromOwner,
            saveDialogOpen, 
            saveInfo,
            incompleteSave,  
            alertOpen,  
            alerts, 
            reimbursementEditing,
            reimbursementDialogOpen,
            reimbursementFormKey, 
            temp_reimbursement_attachments,  
            attachmentReimbursementDialogOpen,
            attachmentReimbursementEditing,
            attachmentReimbursementFormKey, 
            appData, 
            historicAnnualReporting,
            historicAnnualAttachment,
            projTypeOptions,
            attachmentOrder,
            attachmentOrderBy
        } = this.state

        //this has all parameters for the basemapcontrolapplication
        var layers = [{attribution: "", city_id: 12,city_label: "Riley Watershed District",id: 12, is_basemap: false,
                        layer_type: "Web Map", layernumbers: "0", layerurl: null,
                        name: "Riley Area",on_by_default: true,opacity: 1,order: 0,proxyurl: "",show_popup: false, subdomains: "",
                        tileddynamic: "Feature Service",token: "",webmap_id: "8921af0a403d4e63a39be19e136f0a69", wmslayers: "", x: -93.44004, y: 44.875283,
                        geocoder_left:[], geocoder_bottom:[], geocoder_right:[],geocoder_top:[], map_level:11 }]

        var is_read_only = [];
        var inprogressReportingReadOnly = false
        var annualReportingReadOnly = false;
        // if (
        //     appData &&
        //     appData.apprec &&
        //     (   appData.apprec.status === "Incomplete" ||
        //         appData.apprec.status === "Complete and Under Review" ||
        //         appData.apprec.status === "Pending" ||
        //         appData.apprec.status === "Turned Down" ||
        //         appData.apprec.status === "Approved" ||
        //         appData.apprec.status === "open" ||
        //         appData.apprec.status === "closed" 
        //     )
        // )
        //     is_read_only = true;

        if(appData && appData.apprec.status === "open"){
            annualReportingReadOnly = true
        }else if(appData && appData.apprec.status === 'closed' && appData.main_status === 'active'){
            inprogressReportingReadOnly = true
        }else if(appData && appData.apprec.status === 'closed' && appData.main_status === 'complete'){
            inprogressReportingReadOnly = true
            annualReportingReadOnly = true
        }


        var columnDataAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true },
            { id: "_type", numeric: false, label: "Type", allowSort: true },
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];
        var columnReimbursementAttachments = [
            { id: "actions", numeric: false, label: "", allowSort: false, width: "20px" },
            { id: "name", numeric: false, label: "Name", allowSort: true }, 
            { id: "notes", numeric: false, label: "Notes", allowSort: true },
        ];  

        if (incompleteSave) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This is a confirmation that the grant application has been saved, but not yet submitted to Riley Purgatory Bluff Creek Watershed District. A copy of the grant application
                                has been emailed to the applicant's email address which will include a link to finish the application later.
                                Please check your spam and junk folders if you have not received it or contact the District. 
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        
        if (new_save) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                            Thank you for submitting a site visit request! A copy of your request has been emailed to you. If you do not see it, please check
                            your spam and junk folders before contacting the grant coordinator. A district representive will reach out to you to schedule
                            your site visit.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        if (entryError) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>
                                This grant application was 'not' successfully saved. Please contact the grant 'managing' staff to alert them of the
                                entry failure.
                            </Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }

        if (new_save2) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the grant application has been resubmitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
        // if (new_save3) {
        //     return (
        //         <AppContainerApplication city_id={"12"} page_loading={loading}>
        //             <Grid container spacing={1} style={{ padding: "0 12px" }}>
        //                 <Grid item xs={12} xl={10}>
        //                     <Typography>This is a confirmation that the grant reimbursement has been submitted successfully.</Typography>
        //                 </Grid>
        //             </Grid>
        //         </AppContainerApplication>
        //     );
        // }
        if (new_save4) {
            return (
                <AppContainerApplication city_id={window.location.origin.includes("ms4prod") ? "12" : "2"} page_loading={loading}>
                    <Grid container spacing={1} style={{ padding: "0 12px" }}>
                        <Grid item xs={12} xl={10}>
                            <Typography>This is a confirmation that the grants annual report has been submitted successfully.</Typography>
                        </Grid>
                    </Grid>
                </AppContainerApplication>
            );
        }
         
        let options2 = [
            "Native Planting (add native plants or seeds to landscape)",  
            "Habitat Restoration (restore impermeable or low-permeable area into native habitat)",  
            "Lake Shoreline Restoration",  
            "Waterbody Buffer Strip (native vegetation to protect lake, stream, wetland, pond, etc.)",  
            "Turf Alternative (convert standard lawn to bee or other alternative lawn)",  
            "Stormwater Best Management Practice (add a rain garden, tree trench, pervious pavers, etc.)", 
            "Rain Barrel (for homeowners only)",  
            "Lake Shoreland Health (add native vegetation to shoreland within 50 feet of major lake)"
        ]

        var reportingSteps = []//TODO place ! in !appData below before OR statement to lock down other steps
        if(appData && appData.apprec && appData.apprec.status === "Initial Inquiry"){
            reportingSteps = ["Instructions","Site Visit Request","Project Type Locked","Project Details Locked",
                "Financial Locked","Education Locked" ,"Maintenance Locked","Attachments Locked","Review and Submit Locked"
            ]
            is_read_only = [1,2,3,4,5,6,7,8]

        }
        else if(appData && (appData.apprec.status === 'Site Visit Completed' || appData.apprec.status === 'Save Not Completed')){//HASNT filled out steps 3-8 yet
            reportingSteps = ["Instructions","Site Visit Request","Project Type","Project Details",
                "Financial","Education" ,"Maintenance","Attachments","Review and Submit"
            ]
            is_read_only = [1]

        } else if(appData && appData.apprec.status === 'Application Submitted/Under Review'){//HAS filledout put steps 3-8 just waiting for internal to decide
            reportingSteps = ["Instructions","Site Visit Request","Project Type","Project Details",
                "Financial","Education" ,"Maintenance","Attachments", "Review and Submit"
            ]
            is_read_only = [1,2,3,4,5,6,7,8]

        }else if(appData && appData.apprec.status === 'Executed Agreement'){//close out report phase
            reportingSteps = ["Instructions","Site Visit Request","Project Type","Project Details",
                "Financial","Education" ,"Maintenance","Attachments", "Review and Submit", "Closeout Report"
            ]
            is_read_only = [1,2,3,4,5,6,7,8]

        }else if(appData && appData.apprec.status === 'Project Complete Pending Reimbursement'){//close out has been submitted
            reportingSteps = ["Instructions","Site Visit Request","Project Type","Project Details",
                "Financial","Education" ,"Maintenance","Attachments", "Review and Submit", "Closeout Report"
            ]
            is_read_only = [1,2,3,4,5,6,7,8,9]

        } 
        else if(appData && (appData.apprec.status === 'Project Closed' || appData.apprec.status === 'Project Closed with Professional Maintenance Support')){//close out report phase
            reportingSteps = ["Instructions","Site Visit Request","Project Type","Project Details",
                "Financial","Education" ,"Maintenance","Attachments", "Review and Submit", "Closeout Report", "Annual Mainenance"
            ]
            is_read_only = [1,2,3,4,5,6,7,8,9]

        } 
        else{//NEW APP INQUIRY HASNT BEEN SENT IN
            reportingSteps = ["Instructions","Site Visit Request","Project Type Locked","Project Details Locked",
                "Financial Locked","Education Locked" ,"Maintenance Locked","Attachments Locked","Review and Submit Locked"
            ]
            is_read_only = [2,3,4,5,6,7,8]
        }   

        return (
            <AppContainerApplication
                city_id={"12"} //window.location.origin.includes("ms4prod") ? "45" : "2"}
                page_loading={loading}
                printing={activeStep === "PRINT"}>
                <Form
                    getApi={(el) => (this.formApi = el)}
                    defaultValues={this.defaultValues()}
                    key={mainFormKey}
                    dontValidateOnMount={true}
                    validateOnSubmit={true}
                    validateError={(values) => this.errorValidator(values)}
                    onSubmit={(values, e) => this.submitApplication(values, e)}>
                    {(formApi) => (
                        <form onSubmit={formApi.submitForm}>
                            <>
                                {appData && appData.apprec && appData.apprec.status && (
                                    <Grid container spacing={1} style={{ backgroundColor: "#a3a3a3", marginTop: -15 }}>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Typography className={classes.centerAlign} variant="h6">
                                                Application Status: {appData && appData.apprec.status}{appData && appData.apprec.status === 'Site Visit Completed'? "--to proceed complete step 3 thru 9":''}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}
                                <Grid container spacing={1} style={{ padding: "0 12px" }}> 
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Typography className={classes.centerAlign} variant="h6">
                                            Grant Application Form {appData && appData.grant_number && "#" + appData.grant_number}
                                        </Typography>{" "}
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}></Grid>
                                     
                                        <Typography variant="caption" className={classes.centerAlign2}>
                                            Required fields are marked with an asterisk (*)
                                        </Typography>
                                    </Grid>
                               
                                    {activeStep !== "PRINT" &&  (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Stepper style={{ padding: 8 }} alternativeLabel nonLinear activeStep={activeStep}>
                                                {reportingSteps.map((label, index) => {
                                                    if (label !== "" && label.includes("Locked")) {
                                                        return (
                                                            <Tooltip title={`This page is not available`}>
                                                                <Step key={index}>
                                                                    <StepButton
                                                                        StepIconComponent={LockIcon}
                                                                        disabled
                                                                        className={classes.btnroot}
                                                                        focusVisibleClassName={classes.pointerr}>
                                                                        <StepLabel 
                                                                            classes={{
                                                                                labelContainer: classes.lessMargin,
                                                                            }}>
                                                                            {label.replace('Locked',"")}
                                                                        </StepLabel>
                                                                    </StepButton>
                                                                </Step>
                                                            </Tooltip>
                                                        );
                                                    } else
                                                    return (
                                                        <Step key={label}>
                                                            <StepButton style={{ cursor: "pointer" }} onClick={() => this.handleNext(index)}>
                                                                {label}
                                                            </StepButton>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Grid>
                                    )}
                                    {(activeStep === 0 && activeStep !== "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1">
                                                <b> 
                                                    <u>Instructions</u>
                                                </b>
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Thanks for your interest in the Stewardship Grant Program. Review the {" "}<a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://rpbcwd.org/stewardship-grant"> 
                                                            program policies
                                                        </a>{" "}before you proceed.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} <b>The first step in our Stewardship Grant Program is a site visit.</b> Fill out and submit the form on “2: Site Visit
                                                    Request.” If you are a previous grant recipient interested in new grant project, please fill out the site visit
                                                    request form for the new project. After you submit the site visit request, a watershed district representative will
                                                    reach out to schedule the site visit within 10 business days or less.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} After your site visit is complete, you will receive an email notification that you can move forward with
                                                submitting an application for a Stewardship Grant. If you wait more than a year after your site visit to submit an
                                                application, we may require an additional site visit to re-assess the proposed project site.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>
                                                {`\u2022`} Sections of the application marked with an asterisk (*) are required information. You will not be able to submit
                                                your application unless all required responses are complete.
                                            </Typography>
                                            <Typography gutterBottom style={{ marginLeft: 9 }}>  
                                                {`\u2022`} If you have questions or trouble filling out this form, contact Eleanor Mahon at {" "} 
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href="mailto:emahon@rpbcwd.org">
                                                    emahon@rpbcwd.org
                                                </a>{" "} or 952-687-1348  
                                            </Typography>
                                            <br />
                                         
                                            <br />  
                                        </Grid>
                                    )}
                                    {(activeStep === 1 || activeStep === "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography>Please fill out this form to request a site visit. After submitting, you will receive an
                                            confirmation email at the address you provided (check your spam folder).</Typography>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Applicant
                                                    </Typography>
                                                    
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_first_name"
                                                                label="First Name *"
                                                                fullWidth
                                                                eventHandle={(e)=>{
                                                                let t = saveInfo
                                                                t['owner_first_name'] = e
                                                                this.setState({saveInfo:t})
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_last_name"
                                                                label="Last Name *"
                                                                fullWidth
                                                                eventHandle={(e)=>{
                                                                    let t = saveInfo
                                                                    t['owner_last_name'] = e
                                                                    this.setState({saveInfo:t})
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_mailing_address"
                                                                label="Mailing Address *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_city"
                                                                label="City *"
                                                                fullWidth
                                                            />
                                                            {/* <Select
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_city"
                                                                label="City *"
                                                                options={ MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield"
                                                                ])}
                                                                fullWidth
                                                            /> */}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="owner_state"
                                                                label="State *"
                                                                options={ [{
                                                                    "label": "Minnesota",
                                                                    "value": "MN"
                                                                }]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only.find((r) => (r === 1 ? true : false))} field="owner_zip" label="Zip *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only.find((r) => (r === 1 ? true : false))} field="owner_phone" label="Phone *" fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField 
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))} 
                                                                field="owner_email" 
                                                                label="Email *" 
                                                                fullWidth    
                                                                eventHandle={(e)=>{
                                                                    let t = saveInfo
                                                                    t['owner_email'] = e
                                                                    this.setState({saveInfo:t})
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Property Owner
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6}>
                                                            <Checkbox disabled={is_read_only.find((r) => (r === 1 ? true : false))}  label="Property owner same as applicant?" field="primary_contact" eventHandle={(e)=>{
                                                                this.setState({primaryContact:e }) 
                                                                this.formApi.setValue('secondary_first_name', '')
                                                                this.formApi.setValue('secondary_last_name', '')
                                                                this.formApi.setValue('secondary_organization', '')
                                                                this.formApi.setValue('secondary_title', '')
                                                                this.formApi.setValue('secondary_mailing_address', '')
                                                                this.formApi.setValue('secondary_state', '')
                                                                this.formApi.setValue('secondary_zip', '')
                                                                this.formApi.setValue('secondary_phone', '')
                                                                this.formApi.setValue('secondary_email', '')
                                                                this.formApi.setValue('secondary_city', '')
                                                            }}/>   
                                                        </Grid> 
                                                        <Grid item xs={6}></Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false)) || primaryContact}
                                                                field="secondary_first_name"
                                                                label={primaryContact === true ? "First Name" : "First Name *"}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false)) || primaryContact}
                                                                field="secondary_last_name"
                                                                label={primaryContact === true ? "Last Name" : "Last Name *"}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))  || primaryContact}
                                                                field="secondary_organization"
                                                                label="Organization"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                         <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))  || primaryContact}
                                                                field="secondary_title"
                                                                label="Title"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))  || primaryContact}
                                                                field="secondary_mailing_address"
                                                                label={primaryContact === true ? "Mailing Address" : "Mailing Address *"}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {/* <Select
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false)) || primaryContact}
                                                                field="secondary_city"
                                                                label={primaryContact === true ? "City" : "City *"}
                                                                options={ MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield"
                                                                ])}
                                                                fullWidth
                                                            /> */}
                                                           <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false)) || primaryContact}
                                                                field="secondary_city"
                                                                label={primaryContact === true ? "City" : "City *"}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))  || primaryContact}
                                                                field="secondary_state"
                                                                label={primaryContact === true ? "State" : "State *"}
                                                                options={  [{
                                                                    "label": "Minnesota",
                                                                    "value": "MN"
                                                                }]}
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only.find((r) => (r === 1 ? true : false))  || primaryContact} field="secondary_zip" label={primaryContact === true ? "Zip" : "Zip *"} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only.find((r) => (r === 1 ? true : false))  || primaryContact} field="secondary_phone" label={primaryContact === true ? "Phone" : "Phone *"} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField disabled={is_read_only.find((r) => (r === 1 ? true : false)) || primaryContact} field="secondary_email" label={primaryContact === true ? "Email" : "Email *"}fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>{" "} 
                                            {activeStep !== "PRINT" && (
                                                <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>      
                                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                        <Typography variant="subtitle1" gutterBottom style={{marginBottom:-7}}>
                                                            Type in an address within the maps <u>search</u> tool to auto populate the <u>projects</u> address and city.<b>{" "} Note: valid addresses are within the boundary area shown on the map.</b>
                                                        </Typography>
                                                    </Grid>
                                                    <br></br>
                                                    <BaseMapControlApplication
                                                        geometry={appData && appData.geometry?appData.geometry:null}
                                                        saveAddress={this.saveAddress}
                                                        disableSearch={appData && appData.geometry?true:false}
                                                        viewOnly
                                                        application={appData}
                                                        setDialogLoading={this.setDialogLoading}
                                                        address={address}
                                                        city={city}
                                                        zip={zip}
                                                        createPoint={createPoint}
                                                        setPoint={this.setPoint}
                                                        layers={layers}
                                                        cityID={"12"}
                                                    /> 
                                                    
                                                    {dialogLoading && ( 
                                                        <CircularProgress
                                                            size={72}
                                                            style={{ display: "table", marginLeft: "auto", marginRight: "auto", zIndex:90000 }}
                                                        /> 
                                                    )}
                                                </Grid> 
                                            )}
                                        </Grid>

                                    )}
                                    {activeStep === "PRINT" && (
                                        <>
                                            <div style={{ height: 50 }}></div>
                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                                <Divider className={classes.printDivider} />
                                            </Grid>
                                        </>
                                    )}
                                    {(activeStep === 1 || activeStep === "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}> 
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b>Project Information</b>
                                            </Typography>
                                            <Grid container spacing={2}> 
                                                <Grid container spacing={2} style={{ padding: 10 }}>
                                                    <Grid item xs={6}>
                                                        <Checkbox disabled={is_read_only.find((r) => (r === 1 ? true : false))} label="Project doesn't have an address?" 
                                                            field="doesnt_have_address" 
                                                            eventHandle={(e)=>{
                                                            this.setState({hasAddress:!e})
                                                        }} />   
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    {hasAddress &&(<>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label={
                                                                    <HelpLabel
                                                                        inputLabel="Project Address *"
                                                                        helpText="Address of site.
                                                                        "
                                                                    />
                                                                }
                                                                field="project_address"
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                fullWidth
                                                            
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {/* <Select
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="project_city"
                                                                label="City Name *"
                                                                options={MAKE_OPTIONS([
                                                                    "Bloomington","Eden Prairie","Deephaven","Hopkins","Minnetonka","Richfield", "Chanhassen", "Chaska", "Victoria"
                                                                ])}
                                                                fullWidth
                                                                
                                                            /> */}
                                                            <TextField
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                field="project_city"
                                                                label="City Name *"
                                                                fullWidth
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Project Zip" 
                                                                field="project_zip"
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                fullWidth
                                                            
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6}> 
                                                        </Grid>
                                                    </>  
                                                    )}
                                                    {!hasAddress &&(
                                                        <>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Street Name "
                                                                            helpText="If your project is occurring along a street, please include the name here.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="project_address"
                                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Select
                                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                    field="project_city"
                                                                    label="City Name *"
                                                                    options={MAKE_OPTIONS([
                                                                        "Bloomington","Eden Prairie","Edina","Hopkins","Minnetonka","Richfield","Minneapolis"
                                                                    ])}
                                                                    fullWidth
                                                                    
                                                                />
                                                            </Grid>     
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Starting Roadway"
                                                                            helpText="If your project is occurring along a street, please include the bounding crossroads of the project.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="roadway1"
                                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                            <TextField
                                                                    label={
                                                                        <HelpLabel
                                                                            inputLabel="Ending Roadway"
                                                                            helpText="If your project is occurring along a street, please include the bounding crossroads of the project.
                                                                            "
                                                                        />
                                                                    }
                                                                    field="roadway2"
                                                                    disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                    fullWidth
                                                                
                                                                />
                                                            </Grid>
                                                           
                                                        </>
                                                    )}
                                                    {/* <Grid item xs={6}>
                                                        <Button
                                                            onClick={() => this.checkAddress()}
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            variant="contained"
                                                            color="primary"
                                                             >
                                                            Verify Address is within Boundary
                                                        </Button>
                                                    </Grid> */}
                                                    <Grid item xs={12}> 
                                                        <hr/>
                                                    </Grid>
                                                    {/* <Grid item xs={6}>
                                                        <TextField
                                                            label="What is the name of your organization (if applicable)?" 
                                                            field="org_name"
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            fullWidth
                                                        
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="If you represent an organization, what is your title and/or role?" 
                                                            field="org_rep"
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            fullWidth
                                                        
                                                        />
                                                    </Grid> */}
                                                    {/* <Grid item xs={12}>
                                                        <br/>
                                                    </Grid> */}
                                                    <Grid item xs={12}>
                                                        <CheckboxGroup
                                                            field="proj_interested"
                                                            label="What type of project(s) are you interested in (check all that apply)? *"
                                                            row={true}
                                                            width={205}
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            // onlyAllowOne={true}
                                                            options={MAKE_OPTIONS(options2)} 
                                                            eventHandle={(e) => {   
                                                            }}
                                                        />
                                                    </Grid>
                                                    {/* <Grid item xs={12}>
                                                        <br/>
                                                    </Grid> */}
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Please describe your project idea. *" 
                                                            field="proj_desc"
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}> 
                                                        {/* <Select
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            field="proj_loc"
                                                            label="Desired Location on Property. *"
                                                            options={MAKE_OPTIONS([
                                                                "Boulevard","Front Yard","Back Yard","Side of House","Near Alley","Driveway","Garage Roof","House Roof","Other"
                                                            ])}
                                                            fullWidth
                                                                
                                                        /> */}
                                                        <TextField
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            field="proj_loc"
                                                            label="Desired Location on Property. *"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Select
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            field="how_hear"
                                                            label="How did you hear about RPBCWD's grant program?"
                                                            options={MAKE_OPTIONS([
                                                                "RPBCWD website","Event","RPBCWD presentation","Social Media","Newspaper/Newsletter","Minnesota Water Steward","Another Grantee/Project","Neighbor","Other"
                                                            ])}
                                                            fullWidth
                                                                
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" style={{paddingBottom:0}} >
                                                            <b>Demographic Information</b>
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography >
                                                            Your answers below will not be shared outside our office. They will only be used to improve the grant program's outreach efforts.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid container spacing={2} style={{ padding: 10 }}>
                                                        <Grid item xs={12}>
                                                            <CheckboxGroup
                                                                field="age"
                                                                label="What is your age? (Limit to one answer)"
                                                                row={true}
                                                                onlyAllowOne={true}
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                eventHandle={this.maxChecked}
                                                                options={MAKE_OPTIONS([
                                                                    "24 years or less",
                                                                    "25-34 years",
                                                                    "35-44 years",
                                                                    "45-54 years",
                                                                    "55-64 years",
                                                                    "65-74 years",
                                                                    "75 years or above",
                                                                ])}
                                                            />
                                                            <CheckboxGroup
                                                                field="education"
                                                                label="What is your highest completed level of education? Or, if currently enrolled, indicate current enrollment degree. (Limit to one answer)"
                                                                row={true}
                                                                onlyAllowOne={true}
                                                                eventHandle={this.maxChecked}
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                options={MAKE_OPTIONS([
                                                                    "Some high school/no diploma",
                                                                    "High school diploma or equivalent",
                                                                    "Some college/no degree",
                                                                    "Trade/technical/vocational training",
                                                                    "Associate degree",
                                                                    "Bachelor's degree",
                                                                    "Master's or PhD",
                                                                ])}
                                                            />
                                                            <CheckboxGroup
                                                                field="race"
                                                                label="Check all categories that describe your race and/or ethnicity (Check all that apply)"
                                                                row={true}
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                // eventHandle={(e) => {
                                                                //     if (e.includes("Other")) {
                                                                //         this.setState({ display_other_race: true });
                                                                //     } else {
                                                                //         this.setState({ display_other_race: false });
                                                                //     }
                                                                // }}
                                                                options={MAKE_OPTIONS([
                                                                    "White or Caucasian",
                                                                    "Black or African American",
                                                                    "Hispanic or Latino",
                                                                    "Asian or Asian American",
                                                                    "American Indian or Alaska Native",
                                                                    "Middle Eastern or North African",
                                                                    "Native Hawaiian or other Pacific Islander",
                                                                    "Other race/ethnicity/or origin",
                                                                ])}
                                                            />
                                                            {/* {display_other_race && (
                                                                <Subquestion
                                                                    component={
                                                                        <TextField
                                                                            label="What other race, ethnicity, and/or origin?"
                                                                            field="other_race"
                                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                            fullWidth
                                                                        />
                                                                    }
                                                                />
                                                            )} */}
                                                            <CheckboxGroup
                                                                field="income"
                                                                label="Before taxes, what is your annual household income? (Limit to one answer)"
                                                                row={true}
                                                                onlyAllowOne={true}
                                                                eventHandle={this.maxChecked}
                                                                disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                                options={MAKE_OPTIONS([
                                                                    "Less than $25,000",
                                                                    "$25,000 to $34,999",
                                                                    "$35,000 to $49,999",
                                                                    "$50,000 to $74,999",
                                                                    "$75,000 to $99,999",
                                                                    "$100,000 to $124,999",
                                                                    "$125,000 or more",
                                                                ])}
                                                            />
                                                        </Grid>
                                                    </Grid>  
                                                    <Grid item xs={9}>
                                                    <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                                        <b>Site Visit Attendance</b> (*required)
                                                    </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="onsite_visit"
                                                            options={MAKE_OPTIONS(["I do not need to be present for the site visit.", "I would like to be present for the site visit."])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                          
                                                            label="Please select one of the following to indicate your attendance for the site visit. If you elect to not be onsite for the site visit,
                                                            you are giving RPBCWD permission to access your property to conduct the site visit without your presence. RPBCWD will give advanced notice of a site visit. * "
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Site visits are scheduled on weekdays (Monday - Friday) during business hours (8 am to 5 pm). What days of the week and times of day work best for you? *" 
                                                            field="site_time"
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="If you believe a site visit is not necessary for your project, please explain why?" 
                                                            field="site_necessary"
                                                            disabled={is_read_only.find((r) => (r === 1 ? true : false))}
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                        
                                                        />
                                                    </Grid>
                                                </Grid>   
                                            </Grid>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}   
                                    {(activeStep === 2 || activeStep === "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginTop:5 }} classes={{ root: classes.root }}>  
                                            <Grid container spacing={2}> 
                                                <Grid item xs={6}style={{paddingTop:13}}>
                                                    <ReactSelect
                                                        label="Applicant Type *"
                                                        field="grant_type"
                                                        options={MAKE_OPTIONS([
                                                            "Residential", 
                                                            "Non-profit",
                                                            "Government",
                                                            "Business",
                                                            "School", 
                                                        ])}
                                                        disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                        // isMulti={true}
                                                        height="auto"
                                                        fullWidth
                                                        eventHandle={grantType=>{  
                                                            let projTypeOptions = []
                                                            if(grantType === 'Residential'){
                                                                projTypeOptions = [
                                                                    "Native Plants", "Turf Alternative", "Rain Barrel", "Habitat Restoration", "Lake Shore Restoration", "Waterbody Buffer", "Stormwater BMP", "Shoreland Health"
                                                                ]
                                                                this.formApi.setValue('rules', '')//clear incase they had soemthing not allowed on this step
                                                            }else if (grantType === 'Non-profit' || grantType === 'Non-profit' || grantType === 'Government' || grantType === 'Business' || grantType === 'School'){
                                                                projTypeOptions = [
                                                                    "Native Plants", "Turf Alternative",  "Habitat Restoration", "Lake Shore Restoration", "Waterbody Buffer", "Stormwater BMP",  
                                                                ]
                                                                this.formApi.setValue('rules', '')
                                                            }
                                                            this.setState({projTypeOptions})
                                                        }}
                                                    />
                                                </Grid>  
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={
                                                            <HelpLabel
                                                                inputLabel="Project Name"
                                                                helpText="Please name your project; ex: Johnson Pocket Planting.
                                                                "
                                                            />
                                                        }
                                                        field="project_name"
                                                        disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                        fullWidth
                                                    />
                                                </Grid> 
                                            </Grid>
                                            <br></br> 
                                            {projTypeOptions && projTypeOptions.length > 0 && ( 
                                                <CheckboxGroup
                                                    field="rules"
                                                    label="Project Type (select one) *"
                                                    row={true}
                                                    width={205}
                                                    disabled={is_read_only.find((r) => (r === 3 ? true : false))}
                                                    onlyAllowOne={true}
                                                    options={MAKE_OPTIONS(projTypeOptions)} 
                                                    eventHandle={(e) => {   
                                                        this.setState({projectType:e}) 
                                                    }}
                                                /> 
                                            )}
                                            <br></br> 
                                            {projectType.length > 0 && projectType.find(t=>t ==="Stormwater BMP") && (  
                                                    <>
                                                     <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}> 
                                                            What type of Stormwater BMP is this? It must align with practices outlined in the <a rel="noopener noreferrer" target='_blank'href='https://stormwater.pca.state.mn.us/index.php?title=Main_Page'>Minnesota Stormwater Manual</a>*.

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}> 
                                                        <TextField
                                                            field="bmp_type" 
                                                            label={""} 
                                                            disabled={is_read_only.find((r) => (r === 2 ? true : false))}
                                                            fullWidth
                                                            multiline
                                                            rows={8}
                                                         /> 
                                                    </Grid> 
                                                    </>
                                                )}
                                        </Grid>
                                     
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {activeStep === 3 && projectType.length === 0 && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom style={{textAlign: "center", textDecoration: "underline",fontWeight: "bold"}}>
                                                No Project Types have been selected. Refer to Step 3 and select 'Applicant Type' followed by 'Project Type'
                                            </Typography>
                                        </Grid>
                                    )}
                            
                                    {((activeStep === 3 && projectType.length > 0) || activeStep === "PRINT") && (
                                        <>                
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            {/* <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Project Details
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ paddingBottom: 8, backgroundColor: "f6f0f0" }}> */}
                                                <Grid container spacing={2} style={{marginTop:10}}>  
                                                    <Grid item xs={6} >
                                                        <TextField
                                                            label={
                                                                <HelpLabel
                                                                    inputLabel="Describe your project size in square feet. Write 'none' if this does not apply to your project. *"
                                                                    helpText="Length x Width in feet.
                                                                    "
                                                                />
                                                            }
                                                            field="prj_size"
                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                            fullWidth
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label={"Describe the location of the project on your property. *"}
                                                            field="prj_loc"
                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                            fullWidth
                                                        />
                                                    </Grid> 
                                                     
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label={"What is the estimated total cost of your project? *"}
                                                            field="prj_est_cost"
                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                            fullWidth
                                                            useTextFormat={true}   
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={6} >
                                                      
                                                    </Grid>
                                                    
                                                </Grid><br></br><hr></hr>
                                                <Grid container spacing={2} style={{marginTop:10}}>   
                                                {(this.formApi.getValue('grant_type') === 'Residential' || this.formApi.getValue('grant_type') === 'Non-profit') && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative" && t !== 'Rain Barrel') && (
                                                    <> 
                                                    <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                                            <b>IN-KIND Credit</b><br></br>  
                                                            <b>If you plan to install the project yourself, you are eligible for in-kind credit (sweat equity).</b> 
                                                            To take advantage of this opportunity, you must include an estimate of in-kind hours and materials in your project estimate at the time 
                                                            of application.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="prj_inkind"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))} 
                                                            label={`Does your estimated total cost include in-kind labor credit? *`} 
                                                        
                                                            
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12}>
                                                        <hr></hr>
                                                    </Grid>
                                                    </>
                                                )} 
                                                {/* {this.formApi.getValue('grant_type') === 'Non-profit' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative") && (  
                                                    <> 
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                                            <b>IN-KIND Credit</b><br></br>  
                                                            <b>If you plan to install the project yourself, you are eligible for in-kind credit (sweat equity).</b> 
                                                            To take advantage of this opportunity, you must include an estimate of in-kind hours and materials in your project estimate at the time 
                                                            of application.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="prj_inkind"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))} 
                                                            label={`Does your estimated total cost include in-kind labor credit?`} 
                                                        
                                                            
                                                        />
                                                    </Grid> 
                                                    <Grid item xs={12}>
                                                        <hr></hr>
                                                    </Grid>
                                                    </>
                                                )}  */}
                                                
                                                <Grid item xs={6} >
                                                    <TextField
                                                        label={"What is the estimated start date for your project? *"}
                                                        field="prj_est_date"
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth
                                                    />
                                                </Grid>  
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={"How many months will it take to install your project? (You must complete your project in 12 months or less). *"}
                                                        field="prj_time"
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth
                                                    />
                                                </Grid> 
                                                    
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label={"Please describe the current condition of the project area, including relevant site history and past management. *"}
                                                        field="prj_cond"
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth 
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>  
                                                <Grid item xs={12} >
                                                    <TextField
                                                        label={`What is your goal(s) with this project? *`}
                                                        field="prj_goal"
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth 
                                                        multiline
                                                        rows={4}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} >
                                                    <TextField
                                                        label={"Who will install the project? *"}
                                                        field="prj_installer"
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth 
                                                    />
                                                </Grid>
                                                    
                                                {projectType.find(t=>t ==="Native Plants" || t ==="Habitat Restoration" || t ==="Lake Shore Restoration" || t ==="Waterbody Buffer" || t ==="Shoreland Health") && ( 
                                                    <>
                                                        <Grid item xs={12}>
                                                            <hr></hr>
                                                            <Typography variant="subtitle1"><b><u>Native Plant Information</u></b></Typography>
                                                        </Grid>     
                                                        <Grid item xs={12}> 
                                                            <Typography gutterBottom>
                                                                <b>Your project may require the use of native plants. Please provide details below.</b>
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                field="native_grower" 
                                                                label="Provide the name(s) of the grower/nursery from where you plan to purchase native plants and/or seeds. If we are not familiar with the names we will ask for more info. *"
                                                                multiline
                                                                rows={4}
                                                                disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                fullWidth
                                                            /> 
                                                        </Grid> 
                                                    </>
                                                )}  
                                                <>
                                                    <Grid item xs={12}>
                                                        <hr></hr>
                                                        <Typography variant="subtitle1"><b><u>Maintenance</u></b></Typography>
                                                    </Grid>     
                                                    <Grid item xs={12}> 
                                                        <Typography gutterBottom>
                                                            <b>Your project must be maintained for a length of time outlined in any grant offer. For residential grantees, the length of time is 5 years. For others, the time will be vary but will be at least 10 years.</b>
                                                        </Typography>
                                                    </Grid>
                                                    {this.formApi.getValue('grant_type') !== 'Government' && 
                                                        projectType.find(t=>t ==="Habitat Restoration" || t ==="Lake Shore Restoration" || t ==="Waterbody Buffer" || t ==="Stormwater BMP") && ( 
                                                            <Grid item xs={12}> 
                                                                <Typography gutterBottom>
                                                                    <b>Projects that restore native vegetation may be eligible for additional grant funds to help pay for professional maintenance in the first 3 years. If you are offered a grant with professional maintenance funds, you must provide a professional maintenance proposal AND commit to hiring a professional. </b>
                                                                </Typography>
                                                            </Grid>
                                                        )
                                                    }
                                                    
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            field="desc_main" 
                                                            label="Describe how you will maintain the project after it's completed. Include information such as how often you plan to monitor and perform maintenance and what maintenance activities you will perform. *"
                                                            multiline
                                                            rows={4}
                                                            disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                            fullWidth
                                                        /> 
                                                    </Grid> 
                                                </>
                                             
                                                  
                                            </Grid> 
                                                {/* </AccordionDetails>
                                            </Accordion> */}
                                            {/* <Grid container spacing={2} style={{marginTop:10, marginLeft:15}}> 
                                                {this.formApi.getValue('grant_type') === 'Residential' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative" && t !== 'Rain Barrel') && (
                                                    <>
                                                    <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                                            <b>IN-KIND Credit</b><br></br>  
                                                            <b>If you plan to install the project yourself, you are eligible for in-kind credit (sweat equity).</b> 
                                                            To take advantage of this opportunity, you must include an estimate of in-kind hours and materials in your project estimate at the time 
                                                            of application.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="inkind"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 5 ? true : false))} 
                                                            label={`Do you plan to include in-kind labor or materials in your project estimate?`} 
                                                        
                                                            
                                                        />
                                                    </Grid> 
                                                    </>
                                                )} 
                                                {this.formApi.getValue('grant_type') === 'Non-profit' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative") && (  
                                                    <>
                                                    <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10 }}>
                                                            <b>IN-KIND Credit</b><br></br>  
                                                            <b>If you plan to install the project yourself, you are eligible for in-kind credit (sweat equity).</b> 
                                                            To take advantage of this opportunity, you must include an estimate of in-kind hours and materials in your project estimate at the time 
                                                            of application.
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="inkind"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 5 ? true : false))} 
                                                            label={`Do you plan to include in-kind labor or materials in your project estimate?`} 
                                                        
                                                            
                                                        />
                                                    </Grid> 
                                                    </>
                                                )} 
                                                <Grid item xs={12}>
                                                    <Typography variant="subtitle1"><b><u>Native Plant Information</u></b></Typography>
                                                </Grid>     
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="native_who_install" 
                                                        label="Who will install the native plants and/or seeds? *"
                                                        multiline
                                                        rows={4}
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth
                                                    />
                                                    <Typography gutterBottom>
                                                        <i>Will this be you or a consultant/business? If working with a professional, what is their name?</i>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        field="native_grower" 
                                                        label="Provide the name of the grower/nursery of the seeds/plants you intend to purchase. "
                                                        multiline
                                                        rows={4}
                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                        fullWidth
                                                    />
                                                    <Typography gutterBottom>
                                                        <i>You may use more than one approved supplier. When it's time to buy plants, you may substitute with approved supplier if your preferred supplier doesn'thave something in stock.</i>
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                    My project meets the Native Plants Requirements (Plants are not cultivars; At least 9 different species; at least 3 species perbloom season; At least 1 keystone species)
                                                    </Typography>
                                                    <Checkbox  disabled={is_read_only.find((r) => (r === 4 ? true : false))} label="Yes, my project meets the minimum Native Plants requirements. *" field="native_requirements" />   
                                                    <Typography gutterBottom>
                                                        <i>View the requirements for native plants <a href='https://www.rpbcwd.org/nativeplants' target="_blank" rel="noopener noreferrer">HERE</a></i>
                                                    </Typography>
                                                </Grid>

                                            
                                                <Grid item xs={9}>
                                                    <RadioGroup
                                                        field="native_eco"
                                                        options={MAKE_OPTIONS(["Yes, I will use ecologically friendly maintenance.", "No, I do not plan on using ecologically friendly maintenance."])}
                                                        alignment={true}
                                                        disabled={is_read_only.find((r) => (r === 5 ? true : false))} 
                                                        label={`I understand that ecologically friendly maintenance must be used for any native planting grants from RPBCWD.*`} 
                                                        
                                                        
                                                    />
                                                    <Typography gutterBottom>
                                                        <i>See the grant webpage for how RPBCWD defines "ecologically friendly maintenance."</i>
                                                    </Typography>
                                                </Grid>      
                                            </Grid> */}
                                        </Grid>
                                        </>
                                    )}
                                    {(activeStep === 4 || activeStep === "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Accordion defaultExpanded>
                                                <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                    <b><u>Financial</u></b> 
                                                    </Typography>
                                                </AccordionSummary> 
                                            
                                                <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                                    <Grid container spacing={2}> 
                                                        <Grid item xs={12} style={{ marginLeft: 15, marginTop:5 }}>
                                                            <RadioGroup
                                                                field="received_grant_funds"
                                                                options={MAKE_OPTIONS(["Yes", "No"])}
                                                                alignment={true}
                                                                disabled={is_read_only.find((r) => (r === 5 ? true : false))} 
                                                                label={`Do you plan to apply or have you received any other grant funds for this project?`} 
                                                                
                                                                
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ marginLeft: 15, marginTop:5 }}>
                                                            <TextField
                                                                label="If you selected “yes,” please explain."
                                                                field="received_grant_funds_explain" 
                                                                multiline
                                                                rows={4}
                                                                disabled={is_read_only.find((r) => (r === 5 ? true : false))}
                                                                fullWidth 
                                                            />
                                                        </Grid> 
                                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                            <Checkbox disabled={is_read_only.find((r) => (r === 5 ? true : false))} field="pay_upfront"  label="I understand that if I am offered a grant, I will have to pay up front for all project-related costs.
                                                                Reimbursement of grant funds will occur after the project has passed inspection and I've submitted all required documentation. *"/> 
                                                        </Grid>
                                                        {this.formApi.getValue('grant_type') !== 'Residential' && this.formApi.getValue('grant_type') !== 'Government' && ( 
                                                            <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>  
                                                                <Checkbox disabled={is_read_only.find((r) => (r === 5 ? true : false))} field="main_declaration"  label="I understand that a grant award of $10,000 or greater will likely require that I file a maintenance declaration on the property for which the grant was received. 
                                                                    Filing a maintenance declaration is a legal process, and RPBCWD may reimburse a portion of costs incurred with this process."/>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {(activeStep === 5 || activeStep === "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>              
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b><u>Education</u></b>
                                            </Typography> 
                                            <Grid container spacing ={2}>
                                                {/* <Grid item xs={12}>
                                                    <TextField
                                                        label="How will you share the results of your project and inform others about your project’s benefits? *"
                                                        field="ed_share" 
                                                        multiline
                                                        rows={8}
                                                        disabled={is_read_only}
                                                        fullWidth 
                                                    />
                                                </Grid> */}
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="ed_can_share"
                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "Yes" },
                                                            { label: "No", value: "No" }, 
                                                        ]}
                                                        alignment={true}
                                                        label="May we share your project with the community on our website, social and other media? *" 
                                                        
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <RadioGroup
                                                        field="ed_highlight"
                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                        fullWidth
                                                        options={[
                                                            { label: "Yes", value: "Yes" },
                                                            { label: "No", value: "No" }, 
                                                        ]}
                                                        alignment={true}
                                                        label="Could we highlight your project on a tour or training event with prior notice and agreement? *"
                                                        
                                                    />
                                                </Grid>
                                            </Grid> 
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {(activeStep === 6 || activeStep === "PRINT") && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>              
                                            <Typography variant="subtitle1" gutterBottom>
                                                <b><u>Maintenance</u></b>
                                            </Typography> 
                                            <Grid container spacing={2}>
                                                {this.formApi.getValue('grant_type') === 'Residential' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative" && t !== 'Rain Barrel' && t !== 'Shoreland Health') && (
                                                    <>
                                                   <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10, marginTop: 20 }}> 
                                                        <b>Your project must be maintained for a length of time outlined in any grant offer. 
                                                            For residential grantees, the length of time is 5 years. For others, the time will be vary but will be at least 10 years.</b>


                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="prof_main"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 6 ? true : false))} 
                                                            label={`Do you plan to include a professional maintenance estimate with your application?   `} 
                                                            
                                                            
                                                        />
                                                    </Grid> 
                                                    </>
                                                )} 
                                                {this.formApi.getValue('grant_type') === 'Non-profit' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative") && (  
                                                    <>
                                                     <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10, marginTop: 20 }}> 
                                                        <b>Your project must be maintained for a length of time outlined in any grant offer. 
                                                        For residential grantees, the length of time is 5 years. For others, the time will be vary but will be at least 10 years.</b>

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="prof_main"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 6 ? true : false))} 
                                                            label={`Do you plan to include a professional maintenance estimate with your application?   `} 
                                                            
                                                            
                                                        />
                                                    </Grid> 
                                                    </>
                                                )}
                                                {this.formApi.getValue('grant_type') === 'Business' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative") && (  
                                                    <>
                                                     <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10, marginTop: 20 }}> 
                                                        <b>Your project must be maintained for a length of time outlined in any grant offer. 
                                                        For residential grantees, the length of time is 5 years. For others, the time will be vary but will be at least 10 years.</b>

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="prof_main"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 6 ? true : false))} 
                                                            label={`Do you plan to include a professional maintenance estimate with your application?   `} 
                                                            
                                                            
                                                        />
                                                    </Grid> 
                                                    </>
                                                )}
                                                {this.formApi.getValue('grant_type') === 'School' && projectType.length > 0 && projectType.find(t=>t !=="Native Plants" && t !=="Turf Alternative") && (  
                                                    <>
                                                     <Grid item xs={9}>
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 10, marginTop: 20 }}> 
                                                        <b>Your project must be maintained for a length of time outlined in any grant offer. 
                                                        For residential grantees, the length of time is 5 years. For others, the time will be vary but will be at least 10 years.</b>

                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="prof_main"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 6 ? true : false))} 
                                                            label={`Do you plan to include a professional maintenance estimate with your application?   `} 
                                                            
                                                            
                                                        />
                                                    </Grid> 
                                                    </>
                                                )}
                                                
                                                <Grid item xs={12} style={{textAlign:"left", marginBottom:-24}}>  
                                                    <Grid item xs={6}> 
                                                        <Checkbox disabled={is_read_only.find((r) => (r === 6 ? true : false))} field="main_agree"  label={"I agree to maintain this project for the number of years outlined in my grant agreement *"}/>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                    </Grid>
                                                    <Grid item xs={11}> 
                                                        <Typography variant="subtitle1" gutterBottom style={{ marginBottom: 5 }}> 
                                                            <b>Projects that restore native vegetation may be eligible for additional grant funds to help pay for professional
                                                                maintenance in the first 3 years. If you are offered a grant with professional maintenance funds, you must provide a
                                                                professional maintenance proposal AND commit to hiring a professional. </b> 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                        <br></br>  
                                                        </Grid>
                                                    <Grid item xs={12} xl={10}  classes={{ root: classes.root }}>  
                                                        <Typography variant="subtitle1"><b><u>Professional Proposal Attachments</u> </b></Typography>
                                                            <Typography gutterBottom>
                                                            If you have a maintance proposal from a professional, upload it here.  
                                                        </Typography>
                                                        <Grid container spacing={2} style={{ padding: 10 }}>
                                                            <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                                <Button
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            attachmentDialogOpen: true,
                                                                            attachmentEditing: null,
                                                                            attachmentType: "Maintenance Proposal",
                                                                            attachmentFormKey: window.performance.now()
                                                                        })
                                                                    }
                                                                    disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    Add Attachment
                                                                </Button>  
                                                            </Grid> 
                                                        </Grid> 
                                                        <Table style={{ marginTop: 8 }}>
                                                            <EnhancedTableHead
                                                                columnData={columnDataAttachments} 
                                                            />
                                                            <TableBody>
                                                                {temp_attachments.map((n) => 
                                                                    {return n._type === 'Maintenance Proposal' && (
                                                                        <TableRow hover>
                                                                            <CustomTableCell className={classes.nowrap}>
                                                                                {n.file.includes("https") && (
                                                                                    <Tooltip title="Open Link">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            onClick={() => {
                                                                                                window.open(n.file);
                                                                                            }}
                                                                                            className={classes.deleteWidth}>
                                                                                            <ViewIcon color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                )}
                                                                                <Tooltip title="Delete">
                                                                                    <Button
                                                                                        disabled={is_read_only.find((r) => (r === 4 ? true : false))}
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                            if(n.id){
                                                                                                
                                                                                                ormRpbcwdStewardshipGrantAttachmentDelete(n.id)
                                                                                            }
                                                                                            return(this.setState({
                                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                                })) 
                                                                                        }
                                                                                        }
                                                                                        // disabled={is_read_only}
                                                                                        className={classes.deleteWidth}>
                                                                                        <DeleteIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </CustomTableCell>
                                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                                        </TableRow> 
                                                                    )})}
                                                                
                                                            </TableBody>
                                                        </Table>
                                                    </Grid>
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                <br></br> <br></br>
                                                </Grid>
                                                <Typography variant="subtitle1" gutterBottom>
                                                <b><u>Annual Maintenance</u></b>
                                                </Typography> 
                                                    <Grid item xs={6} style={{marginTop:10}}>
                                                        <RadioGroup
                                                            field="main_report"
                                                            disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                            fullWidth
                                                            options={[
                                                                { label: "Yes", value: "Yes" },
                                                                { label: "No", value: "No" }, 
                                                            ]}
                                                            alignment={true}
                                                            label="I will submit a brief yearly report to the Riley Purgatory Bluff Creek Watershed District during the maintenance period. *"   
                                                        />
                                                    </Grid> 
                                                </Grid> 
                                                <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                                    <br></br>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        label="Explain how the project will be maintained during the maintenance agreement period and identify who will perform the maintenance. *"
                                                        field="main_maintain" 
                                                        multiline
                                                        rows={10}
                                                        disabled={is_read_only.find((r) => (r === 6 ? true : false))}
                                                        fullWidth 
                                                    />
                                                </Grid> 
                                                <Grid item xs={12} style={{textAlign:"left", marginBottom:-24}}>  
                                                    <Grid item xs={6}> 
                                                        <Checkbox disabled={is_read_only.find((r) => (r === 6 ? true : false))}  field="main_explain"  
                                                        eventHandle={(e)=> {
                                                            this.setState({mainExplain:e})
                                                            // this.formApi.setValue("main_agree","")
                                                            // this.formApi.setValue("main_report",false)
                                                            // this.formApi.setValue("main_maintain","")
                                                        }}
                                                        label={"Most projects require annual maintenance. If you feel this doesn’t apply to your project, check this box." }/>
                                                    </Grid> 
                                                </Grid> 
                                                 
                                            </Grid> 
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {/* {activeStep === 7 && projectType.length > 0 && projectType.find(t=>t==="Native Plants") && activeStep !== "PRINT" && (
                                        <>
                                        <Grid item xs={12} xl={10}  classes={{ root: classes.root }}>  
                                        <Typography variant="subtitle1"><b>Planting Attachments *</b></Typography>
                                            <Typography gutterBottom>
                                            Please attach an aerial image with your proposed planting layout. This layout can be made by-hand or electronically.
                                        </Typography>
                                        <Grid container spacing={2} style={{ padding: 10 }}>
                                            <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                <Button
                                                    onClick={() =>
                                                        this.setState({
                                                            attachmentDialogOpen: true,
                                                            attachmentEditing: null,
                                                            attachmentType: "Other",
                                                            attachmentFormKey: window.performance.now()
                                                        })
                                                    }
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    variant="contained"
                                                    color="primary">
                                                    Add Attachment
                                                </Button>  
                                            </Grid> 
                                        </Grid> 
                                        <Table style={{ marginTop: 8 }}>
                                            <EnhancedTableHead
                                                columnData={columnDataAttachments} 
                                            />
                                            <TableBody>
                                                {temp_attachments.map((n) => 
                                                    {return n._type === 'Native Planting' && (
                                                        <TableRow hover>
                                                            <CustomTableCell className={classes.nowrap}>
                                                                {n.file.includes("https") && (
                                                                    <Tooltip title="Open Link">
                                                                        <Button
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                window.open(n.file);
                                                                            }}
                                                                            className={classes.deleteWidth}>
                                                                            <ViewIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                )}
                                                                <Tooltip title="Delete">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => {
                                                                            if(n.id){
                                                                                
                                                                                ormRpbcwdStewardshipGrantAttachmentDelete(n.id)
                                                                            }
                                                                            return(this.setState({
                                                                                    temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                })) 
                                                                        }
                                                                        }
                                                                        // disabled={is_read_only}
                                                                        className={classes.deleteWidth}>
                                                                        <DeleteIcon color="primary" />
                                                                    </Button>
                                                                </Tooltip>
                                                            </CustomTableCell>
                                                            <CustomTableCell>{n.name}</CustomTableCell>
                                                            <CustomTableCell>{n._type}</CustomTableCell>
                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                        </TableRow> 
                                                    )})}
                                                
                                            </TableBody>
                                        </Table>
                                        </Grid>
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <br></br>
                                    </Grid>
                                
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <br></br>
                                    </Grid>
                                    {projectType.length > 1 && (
                                    <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                        <Divider className={classes.printDivider} />
                                    </Grid>
                                    )}
                                    </>
                                    )} */}
                                    {(activeStep === 7 && activeStep !== "PRINT") && (<>
                                        <Grid item xs={12} lg={10} xl={8} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1"><u>Attachments</u></Typography>
                                            <Typography gutterBottom>
                                                Please refer to the Stewardship Grant Program website for which attachments are required for your project type.:
                                                <ul> 
                                                    <li><b>Photos:</b> Provide at least two photos of the proposed project area. (All projects.)</li>
                                                    <li><b>Site map:</b> Provide a sketch or map of where the project is on your property. This may be part of your design plan.</li> 
                                                    <li><b>Design plan:</b> Show details of your project design. If using plants, include where you're planting what.</li> 
                                                    <li><b>Cost estimate:</b> Provide a detailed, itemized cost estimate.</li>
                                                    <li><b>Detailed native plant list</b> (this may be included as part of your cost estimate) </li> 
                                                    <li><b>Installation plan:</b> Description of site preparation, erosion control approach, etc.</li>
                                                    <li><b>Other</b> </li> 
                                                </ul>
                                                If you have trouble uploading files, you may email them directly to the grant coordinator <a href='mailto:emahon@rpbcwd.org'>HERE</a>
                                            </Typography> 
                                            <Grid item xs={12}>
                                                <Button
                                                    onClick={() =>
                                                        this.setState({
                                                            attachmentDialogOpen: true,
                                                            attachmentEditing: null,
                                                            attachmentType: "Other",
                                                            attachmentFormKey: window.performance.now()
                                                        })
                                                    }
                                                    disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                    variant="contained"
                                                    color="primary">
                                                    Add Attachment
                                                </Button>
                                           
                                            </Grid>
                                        
                                            <Table style={{ marginTop: 8 }}>
                                                <EnhancedTableHead
                                                    columnData={columnDataAttachments}
                                                    order={attachmentOrder}
                                                    orderBy={attachmentOrderBy}
                                                    onRequestSort={this.handleRequestSort2}
                                                />
                                                <TableBody>
                                                    {temp_attachments.map(n => {
                                                        if(n._type !== 'Maintenance Proposal' && n._type !== 'Closeout Photo' && n._type !== 'Closeout Receipt')  
                                                        return (
                                                            <TableRow hover>
                                                                <CustomTableCell className={classes.nowrap}>
                                                                    {n.file.includes("https") && (
                                                                        <Tooltip title="Open Link">
                                                                            <Button
                                                                                color="primary"
                                                                                onClick={() => {
                                                                                    window.open(n.file);
                                                                                }}
                                                                                className={classes.deleteWidth}>
                                                                                <ViewIcon color="primary" />
                                                                            </Button>
                                                                        </Tooltip>
                                                                    )}
                                                                    <Tooltip title="Delete">
                                                                        <Button
                                                                            disabled={is_read_only.find((r) => (r === 7 ? true : false))}
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                this.setState({
                                                                                    temp_attachments: temp_attachments.filter(tc => tc !== n)
                                                                                })
                                                                            }
                                                                            className={classes.deleteWidth}>
                                                                            <DeleteIcon color="primary" />
                                                                        </Button>
                                                                    </Tooltip>
                                                                </CustomTableCell>
                                                                <CustomTableCell>{n.name}</CustomTableCell> 
                                                                <CustomTableCell>{n._type}</CustomTableCell>
                                                                <CustomTableCell>{n.notes}</CustomTableCell>
                                                            </TableRow>
                                                        )
                                                        else return null
                                                    })}
                                                    {temp_attachments.length < 1 && (
                                                        <TableRow>
                                                            <CustomTableCell colSpan={columnDataAttachments.length} className={classes.centerAlign}>
                                                                No Attachments Added
                                                            </CustomTableCell>
                                                        </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </Grid></>
                                    )}
                                    {(activeStep === 8 || activeStep === 'PRINT') && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                                 <br></br>
                                            </Typography>
                                            <table>
                                                {" "}
                                                <tr>
                                                    <td style={{ whiteSpace: "nowrap", paddingRight: 16 }}>
                                                        <Checkbox disabled={is_read_only.find((r) => (r === 8 ? true : false))} label="I AGREE *" field="signature" />
                                                    </td>
                                                    <td>
                                                        <Typography gutterBottom>
                                                        I hereby apply for Riley Purgatory Bluff Creek cost-share assistance with the proposed activities described in this form and have submitted supporting information necessary for RPBCWD to determine whether to provide cost-share funding. 
                                                        The information submitted and statements made concerning this application are true and correct to the best of my knowledge. I understand that any activities undertaken prior to complete execution of a 
                                                        cost-share agreement with RPBCWD will not be eligible for reimbursement. I further represent that I possess the authority and necessary property-use rights to undertake the proposed activities 
                                                        or I am acting as the duly authorized agent of the property owner. By submitting this application, 
                                                        I authorize RPBCWD and its agents, employees, and contractors to enter the property that is the site of the proposed activities to perform inspections of the site and activities occurring thereon.
                                                        </Typography>
                                                    </td>
                                                </tr> 
                                            </table>
                                        </Grid>
                                    )}
                                    {activeStep === "PRINT" && (
                                        <Grid item xs={12} xl={10} classes={{ root: classes.root }}>
                                            <Divider className={classes.printDivider} />
                                        </Grid>
                                    )}
                                    {(activeStep === 9 || activeStep === 'PRINT') && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                          <Accordion defaultExpanded>
                                              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                  <Typography variant="subtitle1" gutterBottom>
                                                  <b><u>Project Completed Form</u></b> 
                                                  </Typography>
                                              </AccordionSummary> 
                                         
                                              <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}> 
                                                <Grid container spacing={2}>
                                                    <Grid item xs={6}>
                                                        <TextField field="tot_prj_cost"  
                                                            label={"Total project cost *" 
                                                            }  
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                            useTextFormat
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <TextField field="grt_elig_amt"   
                                                            label="Grant-eligible amount of total project cost (if different than total project cost)" 
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                            useTextFormat
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Project Summary</b> 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12} style={{marginTop:-15}}>
                                                        <TextField field="prj_summary"  
                                                            label={"Summarize your project. How did it go? What did you learn? How was the grant process? *"} 
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Receipts</b><br></br> 
                                                            Before your grant money is paid out, you must show proof that you have paid for all project costs in full. 
                                                            An unpaid invoice (amount is due) does not qualify as a receipt. If the grant coordinator cannot determine a receipt's purpose, 
                                                            you will be asked to provide more information.
                                                        </Typography>
                                                    </Grid>    
                                                    {activeStep !== 'PRINT' && (
                                                        <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                            <Typography variant="subtitle1"><b>Closeout Receipt *</b></Typography>
                                                            <Typography gutterBottom>
                                                                1.) Upload all receipts. <br></br>
                                                                
                                                            </Typography>
                                                            <Grid container spacing={2} style={{ padding: 10 }}>
                                                                <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                                    <Button
                                                                        onClick={() =>
                                                                            this.setState({
                                                                                attachmentDialogOpen: true,
                                                                                attachmentEditing: null,
                                                                                attachmentType:'Closeout Receipt',
                                                                                attachmentReimbursementFormKey: window.performance.now(), 
                                                                            })
                                                                        }
                                                                        disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                                        variant="contained"
                                                                        color="primary">
                                                                        Add Attachment
                                                                    </Button>  
                                                                </Grid> 
                                                            </Grid> 
                                                            <Table style={{ marginTop: 8 }}>
                                                                <EnhancedTableHead
                                                                    columnData={columnReimbursementAttachments} 
                                                                />
                                                                <TableBody>
                                                                    {temp_attachments.map((n) =>  
                                                                    {return n._type === 'Closeout Receipt' && (
                                                                        <TableRow hover>
                                                                            <CustomTableCell className={classes.nowrap}>
                                                                                {/* {n.file.includes("https") && ( */}
                                                                                    <Tooltip title="Open Link">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            onClick={() => { //show user what they uploaded if they forgot
                                                                                                var a = document.createElement("a");
                                                                                                a.href = n.file;
                                                                                                a.download = n.name;
                                                                                                document.body.appendChild(a);
                                                                                                a.click();
                                                                                                a.remove();
                                                                                            }}
                                                                                            className={classes.deleteWidth}>
                                                                                            <ViewIcon color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                {/* )} */}
                                                                                <Tooltip title="Delete">
                                                                                    <Button
                                                                                        disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                                                        color="primary"
                                                                                        onClick={() => {
                                                                                        return(this.setState({
                                                                                            temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                            })) 
                                                                                            
                                                                                        }
                                                                                        }
                                                                                        // disabled={is_read_only}
                                                                                        className={classes.deleteWidth}>
                                                                                        <DeleteIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            </CustomTableCell>
                                                                            <CustomTableCell>{n.name}</CustomTableCell> 
                                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                                        </TableRow> 
                                                                    )}
                                                                    )} 
                                                                </TableBody>
                                                            </Table>
                                                            </Grid>
                                                    )}
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="recpt"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))} 
                                                            label={`Do any of the receipts includes purchases not related to the project?`}  
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <TextField field="recpt_comments"  
                                                            label="Write any comments you have about the receipts"  
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                              <br></br>
                                                {activeStep !== 'PRINT' && (
                                                    <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                        <Typography variant="subtitle1"><b>Closeout Photo </b></Typography>
                                                        <Typography gutterBottom>
                                                            1.) Upload at least two (2) photos of your finished project that show the extent of the work. <br></br>
                                                            
                                                        </Typography>
                                                        <Grid container spacing={2} style={{ padding: 10 }}>
                                                            <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                                <Button
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            attachmentDialogOpen: true,
                                                                            attachmentEditing: null,
                                                                            attachmentType:'Closeout Photo',
                                                                            attachmentReimbursementFormKey: window.performance.now(), 
                                                                        })
                                                                    }
                                                                    disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    Add Attachment
                                                                </Button>  
                                                            </Grid> 
                                                        </Grid> 
                                                        <Table style={{ marginTop: 8 }}>
                                                            <EnhancedTableHead
                                                                columnData={columnReimbursementAttachments} 
                                                            />
                                                            <TableBody>
                                                                {temp_attachments.map((n) =>  
                                                                {return n._type === 'Closeout Photo' && (
                                                                    <TableRow hover>
                                                                        <CustomTableCell className={classes.nowrap}>
                                                                            {/* {n.file.includes("https") && ( */}
                                                                                <Tooltip title="Open Link">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => { //show user what they uploaded if they forgot
                                                                                            var a = document.createElement("a");
                                                                                            a.href = n.file;
                                                                                            a.download = n.name;
                                                                                            document.body.appendChild(a);
                                                                                            a.click();
                                                                                            a.remove();
                                                                                        }}
                                                                                        className={classes.deleteWidth}>
                                                                                        <ViewIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            {/* )} */}
                                                                            <Tooltip title="Delete">
                                                                                <Button
                                                                                    disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                    return(this.setState({
                                                                                        temp_attachments: temp_attachments.filter((tc) => tc !== n)
                                                                                        })) 
                                                                                        
                                                                                    }
                                                                                    }
                                                                                    // disabled={is_read_only}
                                                                                    className={classes.deleteWidth}>
                                                                                    <DeleteIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell>{n.name}</CustomTableCell> 
                                                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                                                    </TableRow> 
                                                                )}
                                                                )} 
                                                            </TableBody>
                                                        </Table>
                                                        </Grid>
                                                    )}
                                                    <Grid item xs={12}>
                                                        <TextField field="photo_comments"  
                                                            label="Write any comments you have about the receipts"  
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="pref_reimb"
                                                            options={MAKE_OPTIONS(["I would like the check to be mailed", "I would like to pick up the check in person at the RPBCWD office during regular weekday business hours"])}
                                                            alignment={true}
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))} 
                                                            label={`Select your preferred reimbursement delivery method (check one) *`}  
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            Reimbursement may take 2-6 weeks. When you receive your reimbursement payment depends on several factors 
                                                            including when you submit your close-out report, timing of project inspection, and where RPBCWD is in its monthly financial cycle. Cuts are only cut once per month.
                                                        </Typography>
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Comments</b> 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="addtl_comments"  
                                                            label="Please provide any additional comments you have about your experience with the grant program."  
                                                            disabled={is_read_only.find((r) => (r === 9 ? true : false))}
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Thank you!</b><br></br>  
                                                        </Typography>
                                                    </Grid> 
                                                </Grid>
                                              </AccordionDetails>
                                          </Accordion>
                                      </Grid>
                                    )}
                                    {(activeStep === 10 || activeStep === 'PRINT') && (
                                        <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                          <Accordion defaultExpanded>
                                              <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                                  <Typography variant="subtitle1" gutterBottom>
                                                  <b><u>Annual Maintenance Report</u></b> 
                                                  </Typography>
                                              </AccordionSummary> 
                                         
                                              <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}> 
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            We'd like to hear how your grant project is doing. Please reach out to the grant coordinator with any concerns.
                                                        </Typography>
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <Select
                                                            // disabled={is_read_only.find((r) => (r === 10 ? true : false))}
                                                            field="maint"
                                                            label="Have you maintained your project over the last year?"
                                                            options={MAKE_OPTIONS([//eslint-disable-next-line
                                                                "Yes\, and things are going well","Yes\, but I could use some advice/input","No\, but my project is a structure that needs minimal/no maintenance",
                                                                "No because I've had cost or time limitations.","No because of another reason." 
                                                            ])}
                                                            fullWidth
                                                                
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="prj_going"  
                                                            label={"How is the project doing? It is serving its intended purpose(s)? *"}  
                                                            multiline rows={3} 
                                                            fullWidth/> 
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="visit"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true} 
                                                            label={`Would you like to schedule a visit to discuss your project?`}  
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={12} style={{marginTop:-15}}>
                                                        <TextField field="phone"  
                                                            label={"Provide your most current phone number and email. *"}  
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>    
                                                    <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                        <Typography variant="subtitle1"><b>Annual Photos</b></Typography>
                                                        <Typography gutterBottom>
                                                            1.) Provide at least one high resolution photo of the project. <br></br> 
                                                        </Typography>
                                                        <Grid container spacing={2} style={{ padding: 10 }}>
                                                            <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                                <Button
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            attachmentReimbursementDialogOpen: true,
                                                                            attachmentReimbursementEditing: null,
                                                                            attachmentReimbursementFormKey: window.performance.now(), 
                                                                        })
                                                                    }
                                                                    disabled={annualReportingReadOnly}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    Add Attachment
                                                                </Button>  
                                                            </Grid> 
                                                        </Grid> 
                                                        <Table style={{ marginTop: 8 }}>
                                                            <EnhancedTableHead
                                                                columnData={columnReimbursementAttachments} 
                                                            />
                                                            <TableBody>
                                                                {temp_reimbursement_attachments.map((n) =>  
                                                                    <TableRow hover>
                                                                        <CustomTableCell className={classes.nowrap}>
                                                                            {/* {n.file.includes("https") && ( */}
                                                                                <Tooltip title="Open Link">
                                                                                    <Button
                                                                                        color="primary"
                                                                                        onClick={() => { //show user what they uploaded if they forgot
                                                                                            var a = document.createElement("a");
                                                                                            a.href = n.file;
                                                                                            a.download = n.name;
                                                                                            document.body.appendChild(a);
                                                                                            a.click();
                                                                                            a.remove();
                                                                                        }}
                                                                                        className={classes.deleteWidth}>
                                                                                        <ViewIcon color="primary" />
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            {/* )} */}
                                                                            <Tooltip title="Delete">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => {
                                                                                    return(this.setState({
                                                                                            temp_reimbursement_attachments: temp_reimbursement_attachments.filter((tc) => tc !== n)
                                                                                        })) 
                                                                                        
                                                                                    }
                                                                                    }
                                                                                    // disabled={is_read_only}
                                                                                    className={classes.deleteWidth}>
                                                                                    <DeleteIcon color="primary" />
                                                                                </Button>
                                                                            </Tooltip>
                                                                        </CustomTableCell>
                                                                        <CustomTableCell>{n.name}</CustomTableCell> 
                                                                        <CustomTableCell>{n.notes}</CustomTableCell>
                                                                    </TableRow> 
                                                                )} 
                                                            </TableBody>
                                                        </Table>
                                                        </Grid> 
                                                    <Grid item xs={6}> 
                                                        <Checkbox  field="images"  label={"I understand that any images I submit may be used by RPBCWD to promote its mission. This may include using photos on the district website, social media accounts, or other media. *"}/>
                                                    </Grid>
                                                    <br></br> 
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Comments</b> 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="comments"  
                                                            label="Please provide any additional comments you have related to the project or RPBCWD."   
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Thank you!</b><br></br>  
                                                        </Typography>
                                                    </Grid> 
                                                </Grid>
                                              </AccordionDetails>
                                          </Accordion>
                                      </Grid>
                                    )}
                                    {activeStep !== "PRINT" && (
                                        <Grid item xs={12} xl={10} className={classes.rightAlign} classes={{ root: classes.root }}>
                                            {/* INITIAL INQUIRY CASE */}
                                            {activeStep <= 1 && !appData && (
                                                <>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={() => {this.setState({ activeStep: activeStep - 1 }); window.scrollTo(0, 0)}} 
                                                    >
                                                        Back
                                                    </Button>
                                                   
                                                    <Button
                                                        onClick={() => activeStep === 0 ? this.handleNext() :  this.setState({checkWhatFields:"Initial Inquiry"},()=>{
                                                            this.formApi.submitForm()})}
                                                        disabled={appData} 
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: 16 }}>
                                                        {activeStep === 0 ? "Next" : "Submit Inquiry"} 
                                                    </Button>
                                                     
                                                </>
                                            )} 
                                            {/* AFTER INITIAL INQUIRY BUT FULL APP HASNT BEEN SUBMITTED YET */}
                                            {appData && appData.apprec && (appData.apprec.status === 'Site Visit Completed' || appData.apprec.status === 'Save Not Completed') && (
                                                <>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={() => {this.setState({ activeStep: activeStep - 1 }); window.scrollTo(0, 0)}}
                                                        // className={classes.button}
                                                        >
                                                        Back
                                                    </Button>
                                                  
                                                    <Button
                                                        // disabled={activeStep <= 1 || is_read_only}
                                                        onClick={() => {
                                                            let fname = this.formApi.getValue('owner_first_name')
                                                            let lname = this.formApi.getValue('owner_last_name')
                                                            let em = this.formApi.getValue('owner_email')
                                                            let saveObj = {owner_first_name:fname, owner_last_name:lname, owner_email:em}
                                                            this.setState({saveDialogOpen:true, saveInfo:saveObj})
                                                        }}
                                                        variant="contained"
                                                        color="primary" 
                                                        style={{ marginLeft: 16 }}>
                                                        Save and Resume Later
                                                    </Button>
                                                    
                                                   
                                                    <Button
                                                        onClick={() => activeStep < 8 ? this.handleNext() :  this.setState({checkWhatFields:"Site Visit Completed"},()=>{
                                                            this.formApi.submitForm()})} 
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: 16 }}>
                                                        {activeStep < 8 ? "Next" : "Submit Application"} 
                                                    </Button>
                                                   
                                                     
                                                </>
                                            )} 
                                              {/* AFTER INITIAL INQUIRY and full app 1-9 has been submitted from applicant */}
                                              {appData && appData.apprec && appData.apprec.status === 'Application Submitted/Under Review' && (
                                                <>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={() => {this.setState({ activeStep: activeStep - 1 }); window.scrollTo(0, 0)}} 
                                                        >
                                                        Back
                                                    </Button>
                                                   
                                                    <Button
                                                        onClick={() => activeStep < 8 ? this.handleNext(): "" }
                                                        disabled={activeStep === 8}  
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: 16 }}>
                                                        {activeStep < 8 ? "Next" : "Submit Application"} 
                                                    </Button>
                                                     
                                                </>
                                            )} 
                                            {appData && appData.apprec && appData.apprec.status === 'Executed Agreement' && (
                                                <>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={() => {this.setState({ activeStep: activeStep - 1 }); window.scrollTo(0, 0)}}
                                                        // className={classes.button}
                                                        
                                                        >
                                                        Back
                                                    </Button>
                                                   
                                                    <Button
                                                        onClick={() => activeStep < 9 ? this.handleNext() :  this.setState({checkWhatFields:"Executed Agreement"},()=>{
                                                            this.formApi.submitForm()})}
                                                       
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: 16 }}>
                                                        {activeStep < 9 ? "Next" : "Submit Closeout Report"}  
                                                    </Button>
                                                     
                                                </>
                                            )} 
                                            {appData && appData.apprec && (appData.apprec.status === 'Project Closed' || appData.apprec.status === 'Project Closed with Professional Maintenance Support') && (
                                                <>
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={() => {this.setState({ activeStep: activeStep - 1 }); window.scrollTo(0, 0)}} 
                                                        >
                                                        Back
                                                    </Button>
                                                   
                                                    <Button
                                                        onClick={() => activeStep < 10 ? this.handleNext() :  this.setState({checkWhatFields:"yearly"},()=>{
                                                            this.formApi.submitForm()})}
                                                       
                                                        variant="contained"
                                                        color="primary"
                                                        style={{ marginLeft: 16 }}>
                                                        {activeStep < 10 ? "Next" : "Submit Annual Report"}  
                                                    </Button>
                                                     
                                                </>
                                            )}   
                                        </Grid>
                                    )}
                                </Grid>
                                <Dialog open={saveDialogOpen} classes={{ paper: classes.minWidth }}>
                                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                                        <Typography variant="h6" className={classes.flex}>
                                            Save Progress and Resume Later? 
                                        </Typography>
                                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ saveDialogOpen: false })}>
                                            <Close />
                                        </IconButton>
                                    </Toolbar>
                                    <Toolbar>
                                        <Typography variant="h7" className={classes.flex}>
                                            Note: An email will be sent to the below email address where a link will be provided to resume this application. Keep in mind the application will not be processed until its fully filled out and submitted (i.e. step 9).
                                        </Typography>
                                    </Toolbar>
                                    <DialogContent className={classes.dialogMin}>
                                        <DialogContentText>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        disabled={is_read_only}
                                                        field="owner_first_name"
                                                        label="First Name *"
                                                        fullWidth
                                                        eventHandle={(e)=>
                                                            this.formApi.setValue('owner_first_name', e)}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        disabled={is_read_only}
                                                        field="owner_last_name"
                                                        label="Last Name *"
                                                        fullWidth
                                                        eventHandle={(e)=>
                                                        this.formApi.setValue('owner_last_name', e)}
                                                    />
                                                </Grid> 
                                                <Grid item xs={12}>
                                                    <TextField disabled={is_read_only} field="owner_email" label="Email *" fullWidth   eventHandle={(e)=>
                                                        this.formApi.setValue('owner_email', e)} />
                                                </Grid>
                                            
                                                <Grid item xs={6}>
                                                    <Button  fullWidth onClick={() => this.setState({ saveDialogOpen: false})}>
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button   fullWidth type="submit" variant="contained" color="primary"
                                                    onClick={()=>{
                                                 
                                                        this.setState({submittedSave:true, checkWhatFields:'Save Not Completed'},()=>{
                                                        this.formApi.submitForm()})

                                                    }}>
                                                        Submit
                                                    </Button>
                                                </Grid>
                                            </Grid> 
                                        </DialogContentText>
                                    </DialogContent>
                                </Dialog>
                            </>
                        </form>
                    )}
                </Form>
          
               
                {(activeStep === 10 && appData && (appData.apprec.status === 'Project Closed' || appData.apprec.status === 'Project Closed with Professional Maintenance Support' )) && (
                    <Grid item xs={12} xl={10} style={{ marginLeft: 15, marginRight:15 }} classes={{ root: classes.root }}>
                    <Typography style={{marginLeft:15}} variant="subtitle1" gutterBottom>
                        <b><u>Historical Annual Reports for this Application</u></b> 
                    </Typography>   

                    {historicAnnualReporting && historicAnnualReporting.length === 0 && (
                        <Typography style={{marginLeft:15}} variant="subtitle1" gutterBottom>
                        <b>0 Annual Reports for this Application</b> 
                        </Typography>
                    )}
           
                    {historicAnnualReporting && historicAnnualReporting.map((r)=>{     
                        return(
                            <Form
                            dontValidateOnMount={true} 
                            key={r.id}
                            defaultValues={r} 
                            >
                            {(formApi2) => (
                            <form onSubmit={formApi2.submitForm}>
                                <br></br>
                                    <Grid item xs={12} xl={10} style={{ marginLeft: 15 }} classes={{ root: classes.root }}>
                                    <Accordion defaultExpanded={false}>
                                        <AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ backgroundColor: "#ddd" }}>
                                            <Typography variant="subtitle1" gutterBottom>
                                            <b><u>Annual Report from {new Date(r.date_created.replaceAll('-','/')).toLocaleString().split(',')[0]}</u></b> 
                                            </Typography>
                                        </AccordionSummary>  
                                        <AccordionDetails style={{ paddingBottom: 27, backgroundColor: "f6f0f0" }}>
                                            <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            We'd like to hear how your grant project is doing. Please reach out to the grant coordinator with any concerns.
                                                        </Typography>
                                                    </Grid> 
                                                    <Grid item xs={6}>
                                                        <Select
                                                            // disabled={is_read_only.find((r) => (r === 10 ? true : false))}
                                                            field="maint"
                                                            label="Have you maintained your project over the last year?"
                                                            options={MAKE_OPTIONS([//eslint-disable-next-line
                                                                "Yes\, and things are going well","Yes\, but I could use some advice/input","No\, but my project is a structure that needs minimal/no maintenance",
                                                                "No because I've had cost or time limitations.","No because of another reason." 
                                                            ])}
                                                            fullWidth
                                                                
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="prj_going"  
                                                            label={"How is the project doing? It is serving its intended purpose(s)? *"}  
                                                            multiline rows={3} 
                                                            fullWidth/> 
                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <RadioGroup
                                                            field="visit"
                                                            options={MAKE_OPTIONS(["Yes", "No"])}
                                                            alignment={true} 
                                                            label={`Would you like to schedule a visit to discuss your project?`}  
                                                        />
                                                    </Grid>  
                                                    <Grid item xs={12} style={{marginTop:-15}}>
                                                        <TextField field="phone"  
                                                            label={"Provide your most current phone number and email. *"}  
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>    
                                                    <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                        <Typography variant="subtitle1"><b>Annual Photos</b></Typography>
                                                        <Typography gutterBottom>
                                                            1.) Provide at least one high resolution photo of the project. <br></br> 
                                                        </Typography>
                                                        <Grid container spacing={2} style={{ padding: 10 }}>
                                                            <Grid item xs={2} style={{display:"grid", paddingLeft:0,height:46}}>
                                                                <Button
                                                                    onClick={() =>
                                                                        this.setState({
                                                                            attachmentReimbursementDialogOpen: true,
                                                                            attachmentReimbursementEditing: null,
                                                                            attachmentReimbursementFormKey: window.performance.now(), 
                                                                        })
                                                                    }
                                                                    disabled={annualReportingReadOnly}
                                                                    variant="contained"
                                                                    color="primary">
                                                                    Add Attachment
                                                                </Button>  
                                                            </Grid> 
                                                        </Grid> 
                                                        <Grid item xs={12} xl={10}  classes={{ root: classes.root }}> 
                                                            <Typography variant="subtitle1"><b>Reimbursement Attachments </b></Typography>
                                                            <Typography gutterBottom>
                                                                1.) Provide at least one high resolution photo of the project.  <br></br> 
                                                            </Typography>
                                                            <Grid container spacing={2} style={{ padding: 10 }}>
                                                                    
                                                            </Grid> 
                                                            <Table style={{ marginTop: 8 }}>
                                                                <EnhancedTableHead
                                                                    columnData={columnReimbursementAttachments} 
                                                                />
                                                                <TableBody>
                                                                    {historicAnnualAttachment.filter(a=>{return a.rpbcwdstewardshipgrantannualreporting ===  r.id ? a: null}).map((n) =>  
                                                                        <TableRow hover>
                                                                            <CustomTableCell className={classes.nowrap}> 
                                                                                    <Tooltip title="Open Link">
                                                                                        <Button
                                                                                            color="primary"
                                                                                            onClick={() => { //show user what they uploaded if they forgot
                                                                                                var a = document.createElement("a");
                                                                                                a.target='_blank'
                                                                                                a.href = n.file;
                                                                                                a.download = n.name;
                                                                                                document.body.appendChild(a);
                                                                                                a.click();
                                                                                                a.remove();
                                                                                            }}
                                                                                            className={classes.deleteWidth}>
                                                                                            <ViewIcon color="primary" />
                                                                                        </Button>
                                                                                    </Tooltip> 
                                                                            </CustomTableCell>
                                                                            <CustomTableCell>{n.name}</CustomTableCell> 
                                                                            <CustomTableCell>{n.notes}</CustomTableCell>
                                                                        </TableRow> 
                                                                    )} 
                                                                </TableBody>
                                                            </Table> 
                                                        </Grid>
                                                    </Grid>    
                                                    <Grid item xs={6}> 
                                                        <Checkbox  field="images"  label={"I understand that any images I submit may be used by RPBCWD to promote its mission. This may include using photos on the district website, social media accounts, or other media. *"}/>
                                                    </Grid>
                                                    <br></br> 
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Comments</b> 
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField field="comments"  
                                                            label="Please provide any additional comments you have related to the project or RPBCWD."   
                                                            multiline rows={3} 
                                                            fullWidth/>  
                                                    </Grid>  
                                                    <Grid item xs={12}>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            <b>Thank you!</b><br></br>  
                                                        </Typography>
                                                    </Grid> 
                                                </Grid> 
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </form>
                        )}
                    </Form>)})} 
                </Grid>
                )}
                <Dialog open={attachmentDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentFormKey}
                                defaultValues={attachmentEditing}
                                validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            {(attachmentType === 'Maintenance Proposal' || attachmentType === 'Other' ||  attachmentType === 'Closeout Photo' || attachmentType === 'Closeout Receipt' ) && (
                                                <>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                        Only these file types can be directly uploaded as an attachment. All other file types should be
                                                        put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                        Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                        Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                    </Typography>
                                            
                                                  <br/>
                                                  <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="name" label="Name *" fullWidth />
                                                </Grid>
                                                {attachmentType === 'Other'  && (
                                                    <Grid item xs={12}> 
                                                        <Select
                                                            field="_type"
                                                            label="Type"
                                                            options={MAKE_OPTIONS([
                                                                "Photos",
                                                                "Site Map",
                                                                "Design Plan",
                                                                "Cost Estimate",
                                                                "Detailed Native Plant List",
                                                                "Installation Plan",
                                                                "Other",  
                                                            ])}
                                                            fullWidth
                                                        /> 
                                                    </Grid>
                                                )} 
                                                <Grid item xs={12}>
                                                    <TextField field="notes" label="Notes" fullWidth />
                                                </Grid>
                                                </>
                                            )}  
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentDialogOpen: false, attachmentType:null })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary"> 
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={attachmentReimbursementDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Attachment
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ attachmentReimbursementDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={attachmentReimbursementFormKey}
                                defaultValues={attachmentReimbursementEditing}
                                // validateError={this.errorValidatorAttachment}
                                onSubmit={(values) => this.addReimbursementAttachment(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <>
                                                <Grid item xs={12}>
                                                    <Typography gutterBottom>
                                                        Only these file types can be directly uploaded as an attachment. All other file types should be
                                                        put into a .zip file and then uploaded. Direct supported file types are: PDF (.pdf), Microsoft
                                                        Word (.doc, .docx), Microsoft Excel (.xls, .xlsx), Images (.png, .jpg, .jpeg, .tiff, .bmp),
                                                        Microsoft Powerpoint (.ppt, .pptx), Zip files (.zip).
                                                    </Typography>
                                            
                                                  <br/>
                                                  <FileInput field="file" id="file_upl" fullWidth label="File *" />
                                              </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="name" label="Name *" fullWidth />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField field="notes" label="Notes" fullWidth />
                                                </Grid>
                                                </> 
                                            <Grid item xs={6}>
                                                <Button fullWidth onClick={() => this.setState({ attachmentReimbursementDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button fullWidth type="submit" variant="contained" color="primary"> 
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog open={expenseDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Add Budget Line Item
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ expenseDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <Toolbar>
                        <Typography variant="h7" className={classes.flex} style={{marginTop:-10}}>
                            Note: Please fill in each expense item.
                        </Typography>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                // getApi={(el) => (this.formApi = el)}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={expenseFormKey}
                                defaultValues={expenseEditing}
                                validateError={this.errorValidatorExpense}
                                onSubmit={(values) => this.addExpense(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField  fullWidth disabled={is_read_only} field="item_name" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Item Description/task *"
                                                            helpText="Line item or task required for project"
                                                        />
                                                    }  
                                                    multiline rows={2}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField useTextFormat disabled={is_read_only}  field="nmcwd_grant" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Requested Funds ($)"
                                                            helpText="Cost of item covered by grant funding"
                                                        />
                                                    }   
                                                    fullWidth   
                                                   />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  useTextFormat disabled={is_read_only} field="match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Monetary Match ($)"
                                                            helpText="Cost of item covered by applicant"
                                                        />
                                                    }   
                                                fullWidth  
                                              />
                                            </Grid> 
                                            <Grid item xs={6}>
                                                <TextField  disabled={is_read_only} field="labor_match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Labor Match (hrs)"
                                                            helpText="In-kind labor provided by the applicant; $20/hour of labor"
                                                        />
                                                    }    
                                                    useNumberFormat
                                                fullWidth  
                                             />
                                            </Grid>  
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}>
                                                <Button  fullWidth onClick={() => this.setState({ expenseDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  disabled={is_read_only} fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog> 
                {/* Step 10 only */}
                <Dialog open={reimbursementDialogOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "lightgray" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Add Reimbursement Line Item
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ reimbursementDialogOpen: false })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <Toolbar>
                        <Typography variant="h7" className={classes.flex} style={{marginTop:-10}}>
                            Note: Please fill in each reimbursement item.
                        </Typography>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>
                            <Form
                                // getApi={(el) => (this.formApi = el)}
                                dontValidateOnMount={true}
                                validateOnSubmit={true}
                                key={reimbursementFormKey}
                                defaultValues={reimbursementEditing}
                                validateError={this.errorValidatorReimbursement}
                                onSubmit={(values) => this.addReimbusement(values)}>
                                {(formApi) => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <TextField  fullWidth disabled={inprogressReportingReadOnly} field="description" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Item Description *"
                                                            helpText="Line item or task required for project"
                                                        />
                                                    }  
                                                    multiline rows={2}/>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField useTextFormat disabled={inprogressReportingReadOnly}  field="requested_amount" 
                                                   label={
                                                        <HelpLabel
                                                            inputLabel="Requested Funds ($)"
                                                            helpText="Cost of item covered by grant funding"
                                                        />
                                                    }   
                                                    fullWidth   
                                                   />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <TextField  useTextFormat disabled={inprogressReportingReadOnly} field="match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Monetary Match ($)"
                                                            helpText="Cost of item covered by applicant"
                                                        />
                                                    }   
                                                fullWidth  
                                              />
                                            </Grid> 
                                            <Grid item xs={6}>
                                                <TextField useNumberFormat disabled={inprogressReportingReadOnly} field="labor_match" 
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="Labor Match (hrs)"
                                                            helpText="In-kind labor provided by the applicant; $20/hour of labor"
                                                        />
                                                    }   
                                                fullWidth  
                                              />
                                            </Grid>  
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}/>
                                            <Grid item xs={6}>
                                                <Button  fullWidth onClick={() => this.setState({ reimbursementDialogOpen: false })}>
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button  disabled={inprogressReportingReadOnly} fullWidth type="submit" variant="contained" color="primary">
                                                    Save
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        </DialogContentText>
                    </DialogContent>
                </Dialog> 
                 <WarningDialog
                    confirmAction={() => {
                        this.setState({ outOfDistrict: false });
                   
                    }}
                    cancelAction={(e) => {
                        this.setState({ outOfDistrict: false});
                    }}
                    noDeleteButtons
                    confirmText="Ok" 
                    open={outOfDistrict}
                    title="Confirm Address is within District Boundaries"
                    text={
                        "For sites located within close proximity to the approximate RPBCWD boundary identified on this map, the applicant must verify the legal property boundary with District staff."
                    }
                />
                <Dialog open={alertOpen} classes={{ paper: classes.minWidth }}>
                    <Toolbar style={{ backgroundColor: "#d3d3d3" }}>
                        <Typography variant="h6" className={classes.flex}>
                            Stewardship grant required fields
                        </Typography>
                        <IconButton aria-label="Close Dialog" onClick={() => this.setState({ alertOpen: false, alerts:"" })}>
                            <Close />
                        </IconButton>
                    </Toolbar>
                    <DialogContent className={classes.dialogMin}>
                        <DialogContentText>       
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography> <b><u>You must fill out the following required fields before submitting your application.</u></b></Typography>
                                    {alerts.split('\n').map((v,ix)=>{
                                        let idx = ix + 1
                                        if(v)return <Typography>{idx}{".) "}{v}</Typography>
                                        else return null
                                    })}
                                </Grid>    
                                <Grid item xs={12}>
                                    <Button fullWidth onClick={(e) => { 
                                            this.setState({ alertOpen: false})}}>
                                        Continue
                                    </Button>
                                </Grid>   
                            </Grid>   
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </AppContainerApplication>
        );
    }
}

RpbcwdGrant_ = connect(
    (state, ownProps) => ({
        application: getApplication(state, ownProps),
        // historic: getHistoric(state, ownProps),
        authState: state.auth, // Admins will have an authstate
    }),
    {
        ...ApplicationRecord.actions,
        ...ApplicationRecordStatus.actions,
        ...RpbcwdStewardshipGrant.actions,
        ...RpbcwdStewardshipGrantAttachment.actions, 
        ...RpbcwdStewardshipGrantExpense.actions, 
        ...RpbcwdStewardshipGrantAnnualReporting.actions,
        ...RpbcwdStewardshipGrantAnnualAttachment.actions,  
    }
)(RpbcwdGrant_);

export default withStyles(styles)(withRouter(RpbcwdGrant_));
